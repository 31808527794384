import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { RootState, useAppSelector } from '@store/index'
import { SubscriptionSingleModalBase } from '@modules/subscription/options/subscription-single-modal-base'
import { BaseModalProps } from '@components/modals/types'
import { formatPrice } from '@helpers/utils'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
  prices?: string[]
}

export const SubscriptionChangeLocalizationModal: React.FC<Props> = ({
  subscriptionDetails,
  toggleIsVisible,
  prices,
}) => {
  const { subscription_disposable_localization_change_price } = useAppSelector(
    (state: RootState) => state.appState.appData.settings,
  )

  const options = [subscription_disposable_localization_change_price]

  if (
    formatPrice(subscription_disposable_localization_change_price) !==
    formatPrice(subscriptionDetails.product?.code_localization_convert_price_brutto || '0')
  ) {
    options.push(subscriptionDetails.product?.code_localization_convert_price_brutto || '0')
  }

  return (
    <SubscriptionSingleModalBase
      title="Zmianę lokalizacji w pakiecie"
      message="Opcja zmiany lokalizacji została dodana"
      amounts={prices ?? options}
      description="Opcja zmiany lokalizacji"
      toggleIsVisible={toggleIsVisible}
      subscriptionDetails={subscriptionDetails}
      url={subscriptionDetails.urls.subscription_change_localization}
    />
  )
}
