import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { createSelectOption } from '@helpers/utils'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { SubscriptionProduct } from '@modules/subscription/models'
import { SubscriptionProductFormOption } from '@modules/subscription/products/create/modal'

interface Response {
  options: CustomReactSelectOption[]
  getDefaultProductFormOptions: (subscriptionProduct?: SubscriptionProduct) => SubscriptionProductFormOption[]
}

export const useSubscriptionProductOptions = (): Response => {
  const { subscription_product_option_kinds } = useSubscriptionAppData()

  const options = subscription_product_option_kinds.map(option => createSelectOption(option.value, option.key))

  const getDefaultProductFormOptions = (subscriptionProduct?: SubscriptionProduct): SubscriptionProductFormOption[] => {
    if (!subscriptionProduct) return []

    return subscriptionProduct.options.map(option => ({
      kind: makeDefaultSelectOption(options, option.kind),
      price_brutto: option.price_brutto,
    }))
  }

  return {
    options,
    getDefaultProductFormOptions,
  }
}
