import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { StatusBadge } from '@modules/promotions/common/status-badge'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { Agreement, AgreementAnnex } from '@modules/hr/agreement/models'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { formatPrice, getById } from '@helpers/utils'
import { getAgreementAnnexNumber } from '@modules/hr/agreement/annexes/helpers'
import { useRemoveAgreementAnnexMutation, useUpdateAgreementAnnexStatusMutation } from '@api/agreements'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAgreementDetails } from '@modules/hr/agreement/details/use-agreement-details'
import classNames from 'classnames'
import { PrintButton } from '@components/print-button'

interface Props extends BaseModalProps {
  annex: AgreementAnnex
  agreement: Agreement
}

export const AgreementAnnexDetailsModal = ({ toggleIsVisible, annex, agreement }: Props): JSX.Element => {
  const { annex_types, job_positions } = useHrAppData()
  const { addSuccessMessage } = useNotificationHook()
  const { refresh } = useAgreementDetails({ agreement, withClear: false, immediateFetch: false })

  const annexType = getById(annex_types, annex.document_type)
  const isActive = annex.status === 'active'

  const [updateStatus, { isLoading }] = useUpdateAgreementAnnexStatusMutation()
  const [removeAnnex, { isLoading: isRemoving }] = useRemoveAgreementAnnexMutation()

  const handleAnnexRemove = () => {
    removeAnnex(annex)
    refresh()

    addSuccessMessage('Sukces', 'Aneks został skasowany.')
    toggleIsVisible()
  }

  const changeStatus = async () => {
    await updateStatus({ url: annex.urls.details, payload: { status: isActive ? 'draft' : 'active' } }).unwrap()
    refresh()

    addSuccessMessage('Sukces', 'Status aneksu został zmieniony.')
    toggleIsVisible()
  }

  const position = getById(job_positions, annex.job_position)

  const annexChanges =
    {
      resignation: [`Umowa zostanie rozwiązana w dniu ${toDefaultDateFormat(annex.effective_date)}`],
      earning_change: [`Zmiana stawki na: ${formatPrice(annex.hourly_rate ?? '0')}`],
      task_change: [`Zmiana stanowiska na: ${position?.name}`],
      task_and_earning_change: [
        `Zmiana stawki na: ${formatPrice(annex.hourly_rate ?? '0')}`,
        `Zmiana stanowiska na: ${position?.name}`,
      ],
    }[annex.document_type] ?? []

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>Aneks do umowy nr. {agreement.number}</ModalHeader>
      <ModalBody className="rounded">
        <table className="table table-sm mb-0">
          <tbody>
            <SubscriptionDetailsInformationRow name="Status aneksu" borderless>
              <StatusBadge
                element={{
                  status: isActive ? 'confirmed' : 'initial',
                  status_display: isActive ? 'Aktywny' : 'Nieaktywny',
                }}
              />
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Numer aneksu">
              {getAgreementAnnexNumber(annex.number)}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Typ aneksu">{annexType?.name}</SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Data wejścia zmiany w życie">
              {toDefaultDateFormat(annex.effective_date)}
            </SubscriptionDetailsInformationRow>
          </tbody>
        </table>
        <hr className="mt-0" />
        <PrintButton url={annex.urls.preview} />
        <p className="fw-semi-bold font-14 mb-2">Szczegóły aneksu</p>
        <ul className="list-group">
          {annexChanges.map((change, index) => (
            <li key={index} className="list-group-item">
              {change}
            </li>
          ))}
        </ul>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        {annex.status === 'draft' && (
          <CommonObjectConfirmAction handleAccept={handleAnnexRemove} isLoading={isRemoving}>
            <Button color="outline-danger">Usuń</Button>
          </CommonObjectConfirmAction>
        )}
        <div className={classNames('d-flex', { 'w-100 justify-content-between': annex.status !== 'draft' })}>
          <Button color="light" onClick={toggleIsVisible} className="mr-2">
            Zamknij
          </Button>
          <CommonObjectConfirmAction
            disabled={isActive || agreement.status !== 'active'}
            handleAccept={changeStatus}
            isLoading={isLoading}
            title={`Aktywacja aneksu ${getAgreementAnnexNumber(annex.number)}`}
            message="Czy na pewno chcesz aktywować aneks?"
          >
            <Button color="green">Aktywuj aneks</Button>
          </CommonObjectConfirmAction>
        </div>
      </ModalFooter>
    </>
  )
}
