import * as React from 'react'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { FormSelect } from '@hyper/forms/form-select'
import { Path, useFormContext, useWatch } from 'react-hook-form'
import { SubscriptionCreateFormInputs } from '@modules/subscription/create/modal'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

export interface SubscriptionProductOptionsFormOption {
  prices: CustomReactSelectOption[]
  name: string
  label: string
}

interface Props {
  option: SubscriptionProductOptionsFormOption
}

export const SubscriptionProductOptionRow = ({ option }: Props): React.ReactNode => {
  const { control, setValue } = useFormContext<SubscriptionCreateFormInputs>()

  const formData = useWatch({ control })
  const priceFormKey = `${option.name}_price_brutto` as Path<SubscriptionCreateFormInputs>

  const isOptionSelected = !!formData[option.name]

  React.useEffect(() => {
    if (option.prices.length === 1) {
      setValue(priceFormKey, option.prices[0])
    }
  }, [formData.product])

  useDidUpdateEffect(() => {
    setValue(priceFormKey, isOptionSelected ? option.prices[0] : null)
  }, [isOptionSelected])

  return (
    <div key={option.name} className="mb-2 d-flex align-items-center w-100">
      <div className="col-4">
        <FormCheckbox name={option.name} label={option.label} />
      </div>
      <FormSelect
        options={option.prices}
        name={priceFormKey}
        selectProps={{ placeholder: 'Cena', isDisabled: !isOptionSelected }}
        formPlainProps={{ colStyle: { maxWidth: 120 }, formGroupClassName: 'mb-0', colClassName: 'px-0' }}
      />
    </div>
  )
}
