import * as React from 'react'
import Table, { TableHeaderValue } from '@components/table/table'
import { HrWorkersListTableRow } from '@modules/hr/workers/list/table-row'
import { HrWorker } from '@modules/hr/workers/models'
import { HrWorkerListFilterParams } from '@modules/hr/workers/list/filters'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import { useGetHrWorkersQuery } from '@api/agreements'

export const HrWorkersTableHeaderValues: TableHeaderValue[] = [
  { title: 'Imię i nazwisko', sortField: 'first_name' },
  { title: 'PESEL' },
  { title: 'Resorty' },
  { title: 'Spółka' },
  { title: 'Stanowisko' },
  { title: 'Status' },
  { title: '' },
]

interface Props {
  filters: HrWorkerListFilterParams
  setFilters: (filters: HrWorkerListFilterParams) => void
}

export const HrWorkersListTable: React.FC<Props> = ({ filters, setFilters }) => {
  const {
    data: workers,
    isLoading,
    isFetching,
  } = useQueryWithPaginationResponse<HrWorker[]>(
    useGetHrWorkersQuery({ ...filters, is_active: !filters.show_removed, high_priority: false }),
  )

  return (
    <Table
      className="table-hover w-100"
      headerValues={HrWorkersTableHeaderValues}
      isLoading={isLoading || isFetching}
      filters={filters}
      setFilters={setFilters}
    >
      {workers.map(worker => (
        <HrWorkersListTableRow key={worker.id} worker={worker} />
      ))}
    </Table>
  )
}
