import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'reactstrap'
import classNames from 'classnames'
import Spinner from '@hyper/spinner'

export interface ButtonWithIconProps {
  icon: string
  iconSize?: string
  text: React.ReactNode
  handleClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
  color?: string
  btnClass?: string
  disabled?: boolean
  role?: string
  isLoading?: boolean
  loadingText?: string
  iconWrapperClassName?: string
  wrapText?: boolean
  type?: 'submit' | 'button'
  btnSize?: 'sm' | 'xs' | 'lg' | 'xl'
  textClass?: string
  spinnerColor?: string
}

export const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  icon,
  iconSize,
  text,
  loadingText,
  handleClick,
  color = 'info',
  btnClass,
  type = 'button',
  disabled = false,
  role,
  isLoading,
  wrapText,
  btnSize,
  iconWrapperClassName,
  textClass,
  spinnerColor = 'white',
}) => (
  <Button
    color={color}
    size={btnSize}
    type={type}
    className={classNames('btn', btnClass, { 'text-nowrap': !wrapText })}
    onClick={handleClick}
    disabled={disabled || isLoading}
    role={role}
  >
    {isLoading && <Spinner size="xs" color={spinnerColor} className="inline-spinner mr-1" />}
    <IconWithText
      icon={isLoading ? '' : icon}
      text={isLoading && loadingText ? loadingText : text}
      iconSize={iconSize}
      wrapperClassNames={iconWrapperClassName}
      textClass={classNames('lh-initial', textClass)}
    />
  </Button>
)
