import * as React from 'react'
import { BaseAnnexCard } from '@modules/hr/common/annexes/base-annex-card'
import { HrWorkerDetails, HrWorkerDocument } from '@modules/hr/workers/models'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { getById } from '@helpers/utils'

interface Props {
  document: HrWorkerDocument
  workerDetails: HrWorkerDetails
}

export const WorkerDocumentCard = ({ document, workerDetails }: Props): React.ReactNode => {
  const { document_types } = useHrAppData()
  const [showDetailsModal] = useModal('HrWorkerDocumentDetailsModal', { document, workerDetails })

  const documentType = getById(document_types ?? [], document.document_type)

  return (
    <BaseAnnexCard
      status={document.status}
      statusDisplay={document.status_display}
      onShowDetailsClick={showDetailsModal}
    >
      <Item title="Numer dokumentu" value={document.number} />
      <Item title="Typ dokumentu" value={documentType?.name} />
      <Item title="Data wprowadzenia zmiany" value={toDefaultDateFormat(document.created)} />
    </BaseAnnexCard>
  )
}

const Item = ({ title, value, style = {} }) => (
  <div className="col-3 p-2" style={style}>
    <small className="d-block font-11">{title}</small>
    <strong>{value}</strong>
  </div>
)
