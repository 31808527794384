import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { useAppSelector } from '@store/index'
import { SubscriptionSingleModalBase } from '@modules/subscription/options/subscription-single-modal-base'
import { BaseModalProps } from '@components/modals/types'
import { useSubscriptionOptionPriceList } from '@modules/subscription/hooks/useSubscriptionOptionPriceList'
import { accommodationTypesMapSelector } from '@store/selectors/dashboard'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
  prices?: string[]
}

export const SubscriptionSingleChangeLocalizationModal: React.FC<Props> = ({
  subscriptionDetails,
  toggleIsVisible,
  prices,
}) => {
  const accommodationTypesMap = useAppSelector(accommodationTypesMapSelector)

  const options = useSubscriptionOptionPriceList({
    subscriptionKind: subscriptionDetails.kind,
    accommodationType: accommodationTypesMap[subscriptionDetails.accommodation_type_id]?.type || 'house',
  })

  const option = options.find(option => option.option_kind === 'localization')

  return (
    <SubscriptionSingleModalBase
      title="Jednorazowa zmianę lokalizacji w pakiecie"
      message="Opcja jednorazowej zmiany lokalizacji została dodana"
      amounts={prices ?? [option?.price_brutto || '0']}
      isDisabled={!option}
      description="Opcja zmiany lokalizacji"
      toggleIsVisible={toggleIsVisible}
      subscriptionDetails={subscriptionDetails}
      url={subscriptionDetails.urls.subscription_single_change_localization}
    />
  )
}
