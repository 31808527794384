import { useGetHrAppDataQuery } from '@api/agreements'
import { HRAppData } from '@modules/hr/agreement/models'
import { TypedQueryResult } from '@api/base'

const emptyAppData: HRAppData = {
  status: 'unknown',
  job_positions: [],
  national_health_fund_branch_list: [],
  companies: [],
  agents: [],
  annex_types: [],
  document_types: [],
}

export const useHrAppData = () => {
  const { data: appData } = useGetHrAppDataQuery() as TypedQueryResult<HRAppData>

  return appData ?? emptyAppData
}
