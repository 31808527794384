import * as React from 'react'
import classNames from 'classnames'

interface Props {
  status: string
  statusDisplay: string
}

export const AgreementStatusBadge = ({ status, statusDisplay }: Props): React.ReactNode => (
  <span
    className={classNames({
      'badge badge-success': status === 'completed',
      'badge badge-success-lighten': status === 'active',
      'badge badge-warning': status === 'verified',
      'badge badge-warning-lighten': status === 'to_verify',
      'badge badge-light-lighten text-default': status === 'draft_incomplete',
      'badge badge-light': status === 'draft',
      'badge badge-danger-lighten': status === 'resignation',
    })}
  >
    {statusDisplay}
  </span>
)
