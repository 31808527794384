import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { Label, Row } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { PackageCreateFormInputs } from '@modules/package/create/modal'
import { usePackageAppData } from '@modules/package/hooks/use-package-app-data'
import { createSelectOption, extractSelectOptionsValues, formatPrice, formatPriceShort } from '@helpers/utils'
import DatePickerInput from '@components/date/date-picker-input'
import { PackageType } from '@models/package'
import { useDebounce } from 'rooks'
import { calculatePackagePrice } from '@api/package'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { startOfToday } from 'date-fns'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'
import { FormSelect } from '@hyper/forms/form-select'
import { UploaderAcceptableFile } from '@components/file-uploader'
import { CustomFileInput } from '@components/custom-file-input'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { PriceLoader } from '@modules/reception/checkin/step-feeding/price-loader'
import { OptionWithAmount } from '@components/option-with-amount'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { useModal } from '@components/modals/use-modal'
import { ProductGastroCardConfigurationFormInputs } from '@modules/package/create/steps/params/product-gastro-voucher-configuration-modal'
import { PackageCreateGastroVoucherRow } from '@modules/package/create/steps/params/params-gastro-voucher-row'
import { PackageTicketEntrance } from '@modules/package/create/steps/params/ticket-entrance'
import { PackageCreateFormParamsDeposit } from '@modules/package/create/steps/params/params-deposit'

interface Props {
  type: PackageType
}

export interface ParamsToCalculate {
  kind?: CustomReactSelectOption
  package_type: PackageType
  houses_sea: number
  apartments_sea: number
  houses_mountains: number
  apartments_mountains: number
  with_cleaning_option: boolean
  rent_towel_amount: number
  house_single_price: CustomReactSelectOption
  apartment_single_price: CustomReactSelectOption
  with_zoo_borysew_tickets: boolean
  with_first_car_parking: boolean
  with_second_car_parking: boolean
  zoo_borysew_tickets_single_price_net: string
  suntago_tickets_single_price_net?: string
  defaults_days: number
}

export const PackageCreateFormParams: React.FC<Props> = ({ type }) => {
  const appData = usePackageAppData()
  const user = useAuthenticatedUser()

  const [price, setPrice] = React.useState({ price_brutto: '0', price_netto: '0' })

  const isHPI = type === PackageType.HPI

  const { setValue, control, getValues, setError } = useFormContext<PackageCreateFormInputs>()

  const defaultsDays = useWatch({ control, name: 'defaults_days' })

  const prices = appData.prices.values.filter(
    row => row.type === (isHPI ? 'HPI' : 'HPR') && row.days === defaultsDays.value,
  )

  const { houses: housesPricesOptions, apartments: apartmentsPricesOptions } = prices.reduce(
    (prev, price) => ({
      ...prev,
      [price.accommodation_type]: [
        ...prev[price.accommodation_type],
        createSelectOption(formatPriceShort(price.price_netto), price.price_brutto, false, price),
      ],
    }),
    { houses: [], apartments: [] },
  )

  const paramsToCalculate = useWatch({
    control,
    name: [
      'defaults_days.value',
      'houses_sea',
      'apartments_sea',
      'apartments_universal',
      'houses_universal',
      'houses_mountains',
      'apartments_mountains',
      'with_cleaning_option',
      'rent_towel_amount',
      'house_single_price.value',
      'apartment_single_price.value',
      'with_zoo_borysew_tickets',
      'with_first_car_parking',
      'with_second_car_parking',
      'kind.value',
      'kind_version.value',
      'zoo_borysew_tickets_single_price_net',
      'suntago_tickets_single_price_brutto',
      'with_suntago_tickets',
      'with_towels',
      'with_gastro_voucher',
      'with_free_media_option',
      'allow_split_code',
      'service_charge_included',
      'gastro_voucher_scopes',
      'with_free_towels',
      'with_free_cleaning',
      'with_free_first_car_parking',
      'with_free_second_car_parking',
    ],
  })

  const { subscriptionKindOptions, getSubscriptionKindVersionOptions } = useSubscriptionKinds()
  const [kind, instruction] = useWatch({ control, name: ['kind', 'instruction'] })
  const subscriptionKindVersionOptions = getSubscriptionKindVersionOptions(kind)

  const { isLoading, action: calculate } = useFormRequest(
    async () => {
      const payload = getValues()
      const accommodationFields = [
        'houses_sea',
        'houses_mountains',
        'apartments_sea',
        'apartments_mountains',
        'houses_universal',
        'apartments_universal',
      ]

      const isAccommodationFilled = accommodationFields.some(field => !!payload[field])

      if (!isAccommodationFilled || !payload.kind) return

      setPrice(
        await calculatePackagePrice(
          extractSelectOptionsValues({
            ...payload,
            package_type: type,
            rent_towel_amount: payload.with_towels ? payload.rent_towel_amount : 0,
            gastro_voucher_scopes:
              payload.with_gastro_voucher && payload.gastro_voucher_scopes
                ? payload.gastro_voucher_scopes.filter(Boolean)
                : [],
          }),
          appData.prices,
        ),
      )
    },
    setError,
    { showGlobalError: true },
  )

  const debounceCalculatePrice = useDebounce(async () => {
    await calculate()
  }, 250)

  React.useEffect(() => {
    debounceCalculatePrice()
  }, [paramsToCalculate])

  const [
    includedTowels,
    withFreeCleaning,
    withCleaningOption,
    withGastroVoucher,
    withFreeTowels,
    withFreeFirstCarParking,
    withFreeSecondCarParking,
    withFirstCarParking,
    withSecondCarParking,
    gastroVoucherScopes,
    gastroVoucherAmount,
  ] = useWatch({
    control,
    name: [
      'with_towels',
      'with_free_cleaning',
      'with_cleaning_option',
      'with_gastro_voucher',
      'with_free_towels',
      'with_free_first_car_parking',
      'with_free_second_car_parking',
      'with_first_car_parking',
      'with_second_car_parking',
      'gastro_voucher_scopes',
      'gastro_voucher_amount',
    ],
  })

  useDidUpdateEffect(() => {
    setValue('apartment_single_price', apartmentsPricesOptions[0])
    setValue('house_single_price', housesPricesOptions[0])
  }, [defaultsDays])

  const handleGastroCardConfigurationModalClose = (data: null | ProductGastroCardConfigurationFormInputs) => {
    if (!data && gastroVoucherScopes?.length && gastroVoucherAmount > 0) {
      return
    }

    if (data) {
      setValue('gastro_voucher_amount', data.declared_sell_price)
      setValue('gastro_voucher_scopes', data.scopes)
    } else {
      clearGastroVoucher()
    }
  }

  const [showModal] = useModal('ProductGastroCardConfigurationModal', {
    scopes: gastroVoucherScopes,
    declared_sell_price: gastroVoucherAmount,
    onClose: handleGastroCardConfigurationModalClose,
  })

  const clearGastroVoucher = () => {
    setValue('with_gastro_voucher', false)
    setValue('gastro_voucher_amount', 0)
    setValue('gastro_voucher_scopes', [])
  }

  useDidUpdateEffect(() => {
    withGastroVoucher ? showModal() : clearGastroVoucher()
  }, [withGastroVoucher])

  return (
    <div>
      <Row>
        <FormSelect
          options={subscriptionKindOptions}
          name="kind"
          label="Rodzaj pakietu"
          formPlainProps={{ colSize: 4 }}
        />
        {!!subscriptionKindVersionOptions.length && (
          <FormSelect
            options={subscriptionKindVersionOptions}
            name="kind_version"
            label="Wersja rodzaju"
            formPlainProps={{ colSize: 4 }}
          />
        )}
        <FormSelect
          options={PackageCreateDefaultDays}
          name="defaults_days"
          label="Ilość dób"
          formPlainProps={{ colSize: 4 }}
        />
        <FormPlain colSize={4} name="expire_after">
          <Label>Data ważności</Label>
          <DatePickerInput
            minDate={startOfToday()}
            isDisabled={!user.hasPerm(UserPermission.PackageCanSetExpireAfter)}
            control={control}
            name="expire_after"
            wrapperClassName="flex-grow"
          />
        </FormPlain>
        {!isHPI && <FormInput label="Prefiks kodów" name="prefix" colSize={3} />}
        <CustomFileInput
          formPlainProps={{ colSize: 5 }}
          label="Instrukcja korzystania z vouchera"
          inputName="instruction"
          acceptFiles={UploaderAcceptableFile.PDF}
          canDelete={!!instruction}
        />
        <hr className="w-100" />
        <div className="d-flex w-100">
          <strong className="col-3 mt-label pt-1">Vouchery Domek</strong>
          <FormInput type="number" label="Morze" colStyle={{ maxWidth: 100 }} name="houses_sea" />
          <FormInput type="number" label="Góry" colStyle={{ maxWidth: 100 }} name="houses_mountains" />
          {isHPI && (
            <FormInput type="number" label="Uniwersalne" colStyle={{ maxWidth: 100 }} name="houses_universal" />
          )}
          <FormSelect
            options={housesPricesOptions}
            name="house_single_price"
            formPlainProps={{ colClassName: 'ml-auto', colSize: 4 }}
            label="Cena netto"
          />
        </div>
        <hr className="w-100" />
        <div className="d-flex w-100">
          <strong className="col-3 mt-label pt-1">Vouchery Apartament</strong>
          <FormInput label="Morze" colStyle={{ maxWidth: 100 }} name="apartments_sea" />
          <FormInput label="Góry" colStyle={{ maxWidth: 100 }} name="apartments_mountains" />
          {isHPI && <FormInput label="Uniwersalne" colStyle={{ maxWidth: 100 }} name="apartments_universal" />}
          <FormSelect
            options={apartmentsPricesOptions}
            name="apartment_single_price"
            formPlainProps={{ colClassName: 'ml-auto', colSize: 4 }}
            label="Cena netto"
          />
        </div>
        <hr className="w-100" />

        <PackageCreateFormParamsDeposit />
        <hr className="w-100" />
        <div className="d-flex align-items-center w-100">
          <strong className="col-3">W cenie vouchera</strong>
          <div className="col-9 d-flex align-items-center flex-wrap">
            <FormCheckbox
              name="with_free_cleaning"
              label="Sprzątanie"
              id="with_free_cleaning"
              className="col-4"
              disabled={withCleaningOption}
            />
            {isHPI && (
              <>
                <FormCheckbox
                  name="with_free_media_option"
                  label="Media"
                  id="with_free_media_option"
                  className="col-4"
                />
                <FormCheckbox
                  name="service_charge_included"
                  label="Opłata eksploatacyjna"
                  id="service_charge_included"
                  className="col-4"
                />
                <FormCheckbox
                  name="with_free_towels"
                  label="Komplet ręczników"
                  id="with_free_towels"
                  className="col-4"
                  disabled={includedTowels}
                />
                <FormCheckbox
                  name="with_gastro_voucher"
                  label="Voucher gastronomiczny"
                  id="with_gastro_voucher"
                  className="col-4 mt-1"
                />
                <FormCheckbox
                  name="with_free_first_car_parking"
                  label="Pierwszy parking"
                  id="with_free_first_car_parking"
                  className="col-4 mt-1"
                  disabled={withFirstCarParking}
                />
                <FormCheckbox
                  name="with_free_second_car_parking"
                  label="Drugi parking"
                  id="with_free_second_car_parking"
                  className="col-4 mt-1"
                  disabled={withSecondCarParking}
                />
              </>
            )}
          </div>
        </div>

        <PackageCreateGastroVoucherRow onClearVoucher={clearGastroVoucher} onShowVoucher={showModal} />
        {isHPI && (
          <>
            <hr className="w-100" />
            <div className="d-flex align-items-center w-100">
              <strong className="col-3">Opcje dodatkowe</strong>
              <div className="col-9 d-flex align-items-center flex-wrap">
                <FormCheckbox
                  name="with_cleaning_option"
                  label="Dolicz sprzątanie"
                  id="with_cleaning_option"
                  className="col-4"
                  disabled={withFreeCleaning}
                />
                <FormCheckbox
                  name="with_first_car_parking"
                  label="Pierwszy parking"
                  id="with_first_car_parking"
                  className="col-4"
                  disabled={withFreeFirstCarParking}
                />
                <FormCheckbox
                  name="with_second_car_parking"
                  label="Drugi parking"
                  id="with_second_car_parking"
                  className="col-4"
                  disabled={withFreeSecondCarParking}
                />
              </div>
            </div>
          </>
        )}

        <hr className="w-100" />
        <div className="col-9 px-0">
          <OptionWithAmount
            optionFieldName="with_towels"
            checkboxProps={{ disabled: withFreeTowels }}
            optionLabel={
              <span>
                Dolicz ilość kompletów ręczników
                <IconWithTooltip
                  icon="uil-exclamation-circle ml-1"
                  tooltipId="towels-id"
                  tooltipPlacement="right"
                  tooltipMessage={
                    <span className="font-11 text-left">
                      Podaj liczbę ręczników doliczaną do każdego kodu pakietowego.
                    </span>
                  }
                  color="text-secondary"
                />
              </span>
            }
            amountInputProps={{
              name: 'rent_towel_amount',
              placeholder: 'Ilość',
              step: '1',
            }}
          />
          {isHPI && (
            <PackageTicketEntrance
              checkboxTitle="Dolicz bilety do Zoo Borysew"
              checkboxName="with_zoo_borysew_tickets"
              inputName="zoo_borysew_tickets_single_price_net"
              tax={appData.prices.package_suntago_tickets_tax_rate}
            />
          )}
        </div>
        <hr className="w-100" />
        <FormCheckbox
          name="default_code_activate"
          label="Kody domyślnie są aktywne?"
          id="default_code_activate"
          className="col-6 pl-4"
        />
        {isHPI && (
          <FormCheckbox
            name="allow_split_code"
            label="Pozwól na dzielenie kodów"
            id="allow_split_code"
            className="col-6"
          />
        )}
        {type === PackageType.HPR && user.hasPerm(UserPermission.PackageCanCreateHpiSourcePackage) && (
          <>
            <hr className="w-100" />

            <div className="w-100 mt-2">
              <FormInput label="Pakiet źródłowy" type="select" colSize={4} name="package_source_type">
                <option value="DEFAULT">BRAK</option>
                <option value="HPI">HPI</option>
              </FormInput>
            </div>
          </>
        )}
      </Row>
      <div
        className="bg-light-grey p-2 d-flex align-items-end justify-content-center mt-2 flex-column"
        style={{ marginInline: -16 }}
      >
        <div className="font-11">
          <strong className="pr-1">Cena brutto</strong>
          <PriceLoader
            price={price.price_brutto}
            content={<span className="fw-semi-bold font-14 lh-initial">{formatPrice(price.price_brutto)}</span>}
            isLoading={isLoading}
          />
        </div>
        <div>
          <strong className="pr-1 font-15">Cena netto</strong>
          <PriceLoader
            price={price.price_netto}
            content={<span className="fw-semi-bold font-16 lh-initial">{formatPrice(price.price_netto)}</span>}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}

export const PackageCreateDefaultDays = [
  createSelectOption('6 dób', 6),
  createSelectOption('5 dób', 5),
  createSelectOption('2 doby', 2),
]
