import { useFormContext } from 'react-hook-form'
import { SubscriptionCreateFormInputs } from '@modules/subscription/create/modal'
import { asDecimal } from '@helpers/utils'
import { SubscriptionProduct } from '@modules/subscription/models'

interface Response {
  totalPrice: string
  clientDataChangeOptionPrice: number
  localizationChangeOptionPrice: number
  totalZooTicketPrice?: string
  totalSuntagoTicketPrice?: string
}

export const useSubscriptionCreateFormPrice = (selectedProduct?: SubscriptionProduct): Response => {
  const methods = useFormContext<SubscriptionCreateFormInputs>()

  if (!selectedProduct) {
    return {
      totalPrice: '0',
      clientDataChangeOptionPrice: 0,
      localizationChangeOptionPrice: 0,
    }
  }

  const data = methods.getValues()

  const clientDataChangeOptionPrice =
    data.allow_change_client_data_price_brutto?.value ?? selectedProduct.change_client_data_price_brutto ?? 0

  const localizationChangeOptionPrice =
    data.allow_subscription_code_localization_convert_price_brutto?.value ??
    selectedProduct.code_localization_convert_price_brutto ??
    0

  const zooTicketPrice =
    data.zoo_borysew_tickets_price_brutto?.value ?? selectedProduct.zoo_borysew_tickets_single_price_brutto ?? 0

  const suntagoTicketPrice =
    data.suntago_tickets_price_brutto?.value ?? selectedProduct.suntago_tickets_single_price_brutto ?? 0

  const totalZooTicketPrice = asDecimal(zooTicketPrice)
    .mul(data?.zoo_borysew_tickets_packs_amount ?? 1)
    .toString()

  const totalSuntagoTicketPrice = asDecimal(suntagoTicketPrice)
    .mul(data?.suntago_tickets_packs_amount ?? 1)
    .toString()

  const totalPrice = asDecimal(selectedProduct.price_brutto)
    .plus(data.allow_change_client_data ? clientDataChangeOptionPrice : 0)
    .plus(data.allow_subscription_code_localization_convert ? localizationChangeOptionPrice : 0)
    .plus(data.with_zoo_borysew_tickets ? totalZooTicketPrice : 0)
    .plus(data.with_suntago_tickets ? totalSuntagoTicketPrice : 0)
    .toString()

  return {
    totalPrice,
    clientDataChangeOptionPrice,
    localizationChangeOptionPrice,
    totalSuntagoTicketPrice,
    totalZooTicketPrice,
  }
}
