import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { CommonObjectConfirmAction } from '@components/common-objects-confirm-action'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { getById } from '@helpers/utils'
import { useGetWorkerDocumentDetailsQuery, useRemoveWorkerDocumentMutation } from '@api/agreements'
import { useNotificationHook } from '@hyper/use-notification-hook'
import classNames from 'classnames'
import { HrWorkerDetails, HrWorkerDocument, HrWorkerDocumentDetails } from '@modules/hr/workers/models'
import { useModal } from '@components/modals/use-modal'
import { AgreementStatusBadge } from '@modules/hr/common/details/status-badge'
import { TypedQueryResult } from '@api/base'
import { PrintButton } from '@components/print-button'

interface Props extends BaseModalProps {
  document: HrWorkerDocument
  workerDetails: HrWorkerDetails
}

export const HrWorkerDocumentDetailsModal = ({ toggleIsVisible, document, workerDetails }: Props): React.ReactNode => {
  const { document_types } = useHrAppData()
  const { addSuccessMessage } = useNotificationHook()
  const { data } = useGetWorkerDocumentDetailsQuery(document.urls.details) as TypedQueryResult<HrWorkerDocumentDetails>

  const [showDocumentStatusChangeModal] = useModal('HrWorkerDocumentStatusChangeModal', {
    document,
    workerDetails,
    onSubmit: toggleIsVisible,
  })

  const documentType = getById(document_types ?? [], document.document_type)

  const [removeDocument, { isLoading: isRemoving }] = useRemoveWorkerDocumentMutation()

  const handleDocumentRemove = () => {
    removeDocument(document)

    addSuccessMessage('Sukces', 'Dokument został skasowany.')
    toggleIsVisible()
  }

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>{documentType?.name}</ModalHeader>
      <ModalBody className="rounded">
        <table className="table table-sm mb-0">
          <tbody>
            <SubscriptionDetailsInformationRow name="Status dokumentu" borderless>
              <AgreementStatusBadge status={document.status} statusDisplay={document.status_display} />
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Numer dokumentu">
              {document.number}
            </SubscriptionDetailsInformationRow>
            <SubscriptionDetailsInformationRow name="Typ dokumentu">
              {documentType?.name}
            </SubscriptionDetailsInformationRow>
          </tbody>
        </table>
        <hr className="mt-0" />
        {document.status !== 'draft' && <PrintButton url={document.urls.download} />}

        <p className="fw-semi-bold font-14 mb-2">Zmiany</p>

        <table className="table table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th className="border-bottom-0">Pole</th>
              <th className="border-bottom-0">Stara wartość</th>
              <th className="border-bottom-0">Nowa wartość</th>
            </tr>
          </thead>
          <tbody>
            {(data?.changes ?? []).map((change, index) => (
              <tr key={index}>
                <td>{change.label}</td>
                <td>{change.old_value}</td>
                <td>{change.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        {document.status === 'draft' && (
          <CommonObjectConfirmAction handleAccept={handleDocumentRemove} isLoading={isRemoving}>
            <Button color="outline-danger">Usuń</Button>
          </CommonObjectConfirmAction>
        )}
        <div className={classNames('d-flex', { 'w-100 justify-content-between': document.status !== 'draft' })}>
          <Button color="light" onClick={toggleIsVisible} className="mr-2">
            Zamknij
          </Button>
          <Button color="green" onClick={showDocumentStatusChangeModal} disabled={document.status === 'active'}>
            Zmień status
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}
