import * as React from 'react'
import Table from '@components/table/table'
import { HrWorkersListTableRow } from '@modules/hr/workers/list/table-row'
import { HrWorker } from '@modules/hr/workers/models'
import { HrWorkerListFilterParams } from '@modules/hr/workers/list/filters'
import { HrWorkersTableHeaderValues } from '@modules/hr/workers/list/table'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import { useGetHrWorkersQuery } from '@api/agreements'

interface Props {
  filters: HrWorkerListFilterParams
  setFilters: (filters: HrWorkerListFilterParams) => void
}

export const HrWorkersHighPriorityTable: React.FC<Props> = ({ filters, setFilters }) => {
  const {
    data: workers,
    isLoading,
    isFetching,
  } = useQueryWithPaginationResponse<HrWorker[]>(
    useGetHrWorkersQuery({ ...filters, is_active: !filters.show_removed, high_priority: true }),
  )

  return (
    <div>
      <h4 className="text-danger fw-semi-bold">Do obsłużenia</h4>

      <Table
        tableWrapperClassName="pb-0"
        className="table-hover w-100 mb-0"
        headerValues={HrWorkersTableHeaderValues}
        filters={filters}
        setFilters={setFilters}
        showPagination={false}
        isLoading={isLoading || isFetching}
      >
        {workers.map(worker => (
          <HrWorkersListTableRow key={worker.id} worker={worker} />
        ))}
      </Table>
      <hr className="my-3" />
    </div>
  )
}
