import * as React from 'react'
import * as R from 'ramda'
import Decimal from 'decimal.js'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Apartment } from '@models/apartment'
import { parseISODate } from '@helpers/date-helper'
import { compareDesc } from 'date-fns'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import parsePhoneNumber from 'libphonenumber-js'

function setDocumentTitle(title) {
  document.title = `${title} | Holidaypark PMS`
}

function getDocumentTitle() {
  return document.title
}

export const useDocumentTitle = (title: string) => {
  React.useEffect(() => {
    const currentTitle = getDocumentTitle()
    setDocumentTitle(title)

    return () => setDocumentTitle(currentTitle)
  }, [title])
}

export const formatPercentage = (value: number | string, options?: { minimumFractionDigits: number }) => {
  const dividedValue = asDecimal(value).div(100).toNumber()

  return Intl.NumberFormat('pl-PL', {
    style: 'percent',
    maximumFractionDigits: 2,
    ...options,
  }).format(dividedValue)
}

export const formatPrice = (value: number | string) =>
  Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  }).format(typeof value === 'string' ? parseFloat(value) : value)

export const formatPriceShort = (value: number | string | null | undefined, removeCurrency = false): string => {
  if (value === null || value === undefined) return removeCurrency ? '' : '- zł'

  const result = formatPrice(value).replace(',00', '')

  if (removeCurrency) {
    return result.replace('zł', '').trim()
  }

  return result
}

export const formatTemperature = (temperature: number | null) => (temperature ? `${temperature}°C` : '-')

export const isUndefined = (obj): boolean => typeof obj === 'undefined'

export const formatPhoneNumber = (phone: string | undefined | null): string => {
  if (!phone) return ''

  return parsePhoneNumber(phone, 'PL')?.formatInternational() || phone
}

export const objectToUrlString = (params: {}) =>
  R.keys(params)
    .map(key => (typeof params[key] !== 'undefined' ? `${key}=${params[key]}` : ''))
    .join('&')

export function handleEnterPress(event: React.KeyboardEvent) {
  if (event.keyCode === 13) {
    event.preventDefault()
    this()
  }
}

export const sumDecimalArray = (decimals: Decimal[]): Decimal =>
  decimals.reduce((prev: Decimal, current: Decimal) => prev.add(current), new Decimal(0))

export const formatNumberWithThousandSeparator = (value: number | string) =>
  String(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const caseInsensitiveFilter = x => y => (x || '').toLowerCase().includes((y || '').toLowerCase())

export function groupByField<T = any>(elements: T[], field: string): Record<string, T[]> {
  return R.groupBy<T>(R.prop(field))(elements)
}

export const getDashboardAppUrl = (path: string) =>
  `${document.location.protocol}//${document.location.host}/dashboard${path}`

export function reOrderArray<T>(array: T[], oldIndex: number, newIndex: number): T[] {
  const newArr = [...array]

  const item = newArr.splice(oldIndex, 1)[0]
  newArr.splice(newIndex, 0, item)

  return newArr
}

export function createSelectOption<L = any, V = any>(
  label: L,
  value: V,
  disabled = false,
  context?,
): CustomReactSelectOption<L, V> {
  return {
    label,
    value,
    disabled,
    context,
  }
}

export const asDecimal = (value: string | null | number | undefined | Decimal): Decimal => {
  value = value || 0
  value = value === 'undefined' ? 0 : value

  try {
    return new Decimal(value === 'None' ? 0 : value || 0)
  } catch (error) {
    console.warn(error)
  }
  return new Decimal(0)
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => {}
export const emptyList = []

export const getIntValue = (value: string | number): number => parseInt(String(value), 10)
export const getFloatValue = (value: string | number, decimalPlaces = 1) => asDecimal(value).toFixed(decimalPlaces)
export const isInt = (value: string | number) => parseInt(String(value)) === value

export const sortByPosition = <T extends { position: number }>(items: T[]): T[] =>
  [...items].sort((a, b) => a.position - b.position)

export const getTextWithoutWhitespaces = (value: string) => value.replace(/ /g, '')

export const getApartmentSortingWeight = (apartment: Apartment) => {
  // A ground floor, B first floor, C second floor, D third floor
  const ApartmentFloorSign = { A: 1, B: 2, C: 3, D: 4 }

  const apartmentWithDotSeparation = apartment.name.replace('/', '.')
  const split = apartmentWithDotSeparation.split('.')

  let apartmentWeight = asDecimal(0)

  if (Object.keys(ApartmentFloorSign).includes(split[1])) {
    apartmentWeight = asDecimal(`${split[0]}.${ApartmentFloorSign[split[1]]}`)
  } else if (!isNaN(parseInt(split[0], 10))) {
    apartmentWeight = asDecimal(split[0])
  }

  return apartmentWeight.toNumber()
}

export const extractSelectOptionsValues = <T extends {}, R>(object: T): R => {
  const isSelectOption = (element: any): element is CustomReactSelectOption => {
    if (typeof element !== 'object' || !element) return false

    const paramsLength = Object.keys(element).length
    if (paramsLength >= 2) {
      return 'label' in element && 'value' in element
    }

    return false
  }

  return Object.entries(object || {}).reduce((cum, [key, value]) => {
    const isValueArray = Array.isArray(value)

    if ((isValueArray && value.every(item => isSelectOption(item))) || isSelectOption(value)) {
      return { ...cum, [key]: isValueArray ? value.map(el => el.value) : value.value }
    }

    return { ...cum, [key]: value }
  }, {}) as R
}

export const getById = <T extends {}>(
  list: T[],
  id: number | string | undefined | null,
  field: keyof T = 'id' as keyof T,
): T | undefined => {
  if (id === undefined || id === null) return undefined
  return list.find(row => row[field] && row[field] == id)
}

export const sortObjectListByDate = function <T>(list: T[], field: string, sorter = compareDesc): T[] {
  return [...list].sort((a: T, b: T) => sorter(parseISODate(a[field]) as Date, parseISODate(b[field]) as Date))
}

export const getAccommodationTypeName = (accommodationTypeId: number) =>
  ACCOMMODATION_TYPES.HOUSES.includes(accommodationTypeId) ? 'Domek' : 'Apartament'

export const formatCreatedBy = (createdBy: string = '') => {
  const [name, lastName, department] = createdBy.split(' ') || []

  return `${name[0]}. ${lastName} ${department}`
}
