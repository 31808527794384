import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionSingleModalBase } from '@modules/subscription/options/subscription-single-modal-base'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
  prices: string[]
}

export const SubscriptionSingleChangeDateModal: React.FC<Props> = ({
  subscriptionDetails,
  toggleIsVisible,
  prices,
}) => (
  <SubscriptionSingleModalBase
    title="Dodaj jednorazową zmianę daty w pakiecie"
    message="Jednorazowa opcja zmiany daty została dodana"
    amounts={prices}
    description="Opcja jednorazowej zmiany daty"
    toggleIsVisible={toggleIsVisible}
    subscriptionDetails={subscriptionDetails}
    url={subscriptionDetails.urls.subscription_single_date_change}
  />
)
