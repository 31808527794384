import * as React from 'react'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { Agreement, AgreementAnnex } from '@modules/hr/agreement/models'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { getById } from '@helpers/utils'
import { getAgreementAnnexNumber } from '@modules/hr/agreement/annexes/helpers'
import { BaseAnnexCard } from '@modules/hr/common/annexes/base-annex-card'

interface Props {
  annex: AgreementAnnex
  agreement: Agreement
  isSubsection?: boolean
  isFirstChild?: boolean
}

export const AgreementAnnexCard = ({ annex, agreement, isSubsection, isFirstChild }: Props): JSX.Element => {
  const { annex_types } = useHrAppData()
  const [showDetailsModal] = useModal('AgreementAnnexDetailsModal', { annex, agreement })

  const annexType = getById(annex_types, annex.document_type)
  const sectionStyles = isSubsection ? subSectionsStyles : undefined

  return (
    <BaseAnnexCard
      status={annex.status}
      statusDisplay={annex.status === 'active' ? 'Aktywny' : 'Nieaktywny'}
      onShowDetailsClick={showDetailsModal}
      isSubsection={isSubsection}
      isFirstChild={isFirstChild}
      sectionStyles={sectionStyles}
    >
      <Item title="Numer aneksu" value={getAgreementAnnexNumber(annex.number)} style={sectionStyles?.number} />
      <Item title="Typ aneksu" value={annexType?.name} style={sectionStyles?.type} />
      <Item title="Obowiązuje od" value={toDefaultDateFormat(annex.effective_date)} />
    </BaseAnnexCard>
  )
}

const Item = ({ title, value, style = {} }) => (
  <div className="col-3 p-2" style={style}>
    <small className="d-block font-11">{title}</small>
    <strong>{value}</strong>
  </div>
)

const subSectionsStyles = {
  row: { marginLeft: 50 },
  status: { maxWidth: 110 },
  number: { minWidth: 313 },
  type: { maxWidth: 200 },
  detailsIcon: { marginLeft: 49, paddingRight: '0px !important' },
} as const
