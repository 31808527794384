import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { BenefitProgramAgreement } from '@modules/benefit-program/models'
import { useGetBenefitProgramAgreementsQuery } from '@api/benefit-programs'
import { BenefitProgramAgreementsTableRow } from '@modules/benefit-program/agreements/list/table-row'
import { useQueryWithPaginationResponse } from '@components/hooks/use-query-hooks'
import { Dispatch, SetStateAction } from 'react'
import { BaseTableFilters } from '@components/table/use-handle-table-filters-change'

const headers: TableHeaderValue[] = [
  { title: 'Numer' },
  { title: 'Imię i nazwisko' },
  { title: 'Dane kontaktowe' },
  { title: 'Wybrany benefit' },
  { title: 'Status' },
  { title: 'Data rozpoczęcia' },
  { title: '' },
]

interface Props {
  filters: TableFilters
  onFiltersChange: Dispatch<SetStateAction<BaseTableFilters>>
  benefitProgramId: number
}

export const BenefitProgramAgreementsTable = ({
  filters,
  onFiltersChange,
  benefitProgramId,
}: Props): React.ReactNode => {
  const { data: benefitProgramAgreements = [], isLoading } = useQueryWithPaginationResponse<BenefitProgramAgreement>(
    useGetBenefitProgramAgreementsQuery({
      params: { ...filters, benefit_program: benefitProgramId },
    }),
  )

  return (
    <Table
      className="table-hover w-100 mb-0"
      headerValues={headers}
      isLoading={isLoading}
      filters={filters}
      setFilters={onFiltersChange}
    >
      {benefitProgramAgreements.map((benefitProgramAgreement: BenefitProgramAgreement) => (
        <BenefitProgramAgreementsTableRow
          key={benefitProgramAgreement.id}
          benefitProgramAgreement={benefitProgramAgreement}
        />
      ))}
    </Table>
  )
}
