import * as React from 'react'
import { SubscriptionProduct } from '@modules/subscription/models'
import { useModal } from '@components/modals/use-modal'

interface TableRowEditProps {
  subscriptionProduct: SubscriptionProduct
}

export const SubscriptionProductListTableRowEdit: React.FC<TableRowEditProps> = ({ subscriptionProduct }) => {
  const [toggleModal] = useModal('SubscriptionProductCreateModal', {
    subscriptionProduct,
    type: subscriptionProduct.type,
  })

  return (
    <span className="font-16" onClick={toggleModal} role="edit-button">
      <i className="uil uil-pen cursor-pointer text-muted" title="Edytuj" />
    </span>
  )
}
