import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { SubscriptionSingleModalBase } from '@modules/subscription/options/subscription-single-modal-base'
import { BaseModalProps } from '@components/modals/types'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
  prices: string[]
}

export const SubscriptionUpgradeToPurpleModal: React.FC<Props> = ({ subscriptionDetails, toggleIsVisible, prices }) => (
  <SubscriptionSingleModalBase
    title="Dodaj jednorazowy dostęp do lokali specjalnych"
    message="Dostęp do lokali specjalnych został dodany"
    description="Opcja dostępu do lokali specjalnych"
    toggleIsVisible={toggleIsVisible}
    amounts={prices}
    subscriptionDetails={subscriptionDetails}
    url={subscriptionDetails.urls.subscription_upgrade_to_purple}
  />
)
