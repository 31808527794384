import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { ReceptionApartmentHousekeepingStatus } from '@modules/reception/common/reception-apartment-housekeeping-status'
import { ReservationDataAccommodationType } from '@modules/reservations/details/base-data/booking/reservation-data-accommodation-type'
import { getReceptionBookingDetails } from '@store/actions/reception-actions'
import { ReceptionBookingDetails } from '@models/reception'
import { useAppDispatch } from '@store/index'
import { TimelineSidebarApartmentLabel } from '@modules/reservations/timeline/timeline-sidebar-apartment-label'

interface Props {
  booking: ReceptionBookingDetails
}

export const ReservationLocalData = ({ booking }: Props): React.ReactNode => {
  const dispatch = useAppDispatch()

  const hasApartmentSelectImprovement = booking.improvements.some(
    improvement => improvement.code === 'apartment_select',
  )

  const handleApartmentStatusUpdate = async () => {
    await dispatch(getReceptionBookingDetails(booking.urls.api_details))
  }
  return (
    <>
      <div className="d-flex align-items-center mb-1">
        <IconWithText
          icon="uil-estate text-secondary me-2 font-16"
          text={`Lokal ${booking.apartment?.name}`}
          textClass="font-weight-bold mr-1 text-nowrap"
        />
        {hasApartmentSelectImprovement && (
          <IconWithTooltip
            icon="icon-lokal"
            tooltipMessage={<span className="font-11">Płatny wybór lokalu</span>}
            tooltipId="apartment-select"
            tooltipPlacement="top"
            className="mr-1"
          />
        )}
        <div className="d-flex align-items-center" style={{ height: 20 }}>
          <TimelineSidebarApartmentLabel apartment={booking.apartment} />
          <ReceptionApartmentHousekeepingStatus
            apartment={booking.apartment}
            onStatusUpdate={handleApartmentStatusUpdate}
          />
        </div>
      </div>
      <ReservationDataAccommodationType accommodation_type_id={booking.accommodation_type_id} />
    </>
  )
}
