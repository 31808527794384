import * as React from 'react'
import { Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { HrWorkerDetails } from '@modules/hr/workers/models'
import { WorkerAgreementCards } from '@modules/hr/workers/details/agreements/worker-agreement-cards'
import { useModal } from '@components/modals/use-modal'
import { ButtonWithIcon } from '@components/button-with-icon'

interface Props {
  workerDetails: HrWorkerDetails
}

export const HrWorkerDetailsAgreements = React.forwardRef<HTMLElement, Props>(
  ({ workerDetails }, ref: React.RefObject<HTMLElement>) => {
    const [showCreateAgreementModal] = useModal('AgreementCreateModal', { workerDetails })

    const canAddNewAgreement =
      workerDetails.is_active &&
      (workerDetails.agreements.length === 0 ||
        workerDetails.agreements.every(agreement => ['resignation', 'completed'].includes(agreement.status)))

    return (
      <Card innerRef={ref}>
        <CardBody>
          <div className="d-flex justify-content-between">
            <ProductCardTitle title="Historia współpracy" isEditAllowed={false} />
            {canAddNewAgreement && (
              <ButtonWithIcon
                icon="uil-plus font-16 lh-0"
                text="Dodaj umowę"
                btnClass="btn btn-green btn-flat mr-n2"
                color="green"
                handleClick={showCreateAgreementModal}
              />
            )}
          </div>
          {workerDetails.agreements.length ? (
            <WorkerAgreementCards agreements={workerDetails.agreements} />
          ) : (
            <p>Lista jest pusta</p>
          )}
        </CardBody>
      </Card>
    )
  },
)
