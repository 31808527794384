import * as React from 'react'
import Table, { TableFilters, TableHeaderValue } from '@components/table/table'
import { AgreementListTableRow } from '@modules/hr/agreement/list/table-row'
import { useAppSelector } from '@store/index'
import { selectHighPriorityAgreements } from '@store/slices/hr-slice'

interface Props {
  filters: TableFilters
  setFilters: (filters: TableFilters) => void
  headers: TableHeaderValue[]
}

export const AgreementHighPriorityListTable: React.FC<Props> = ({ filters, setFilters, headers }) => {
  const agreements = useAppSelector(selectHighPriorityAgreements)

  return (
    <div>
      <h4 className="text-danger fw-semi-bold">Do obsłużenia</h4>

      <Table
        tableWrapperClassName="pb-0"
        className="table-hover w-100 mb-0"
        headerValues={headers}
        filters={filters}
        setFilters={setFilters}
        showPagination={false}
        emptyText="Brak umów priorytetowych"
      >
        {agreements.map(agreement => (
          <AgreementListTableRow key={agreement.id} agreement={agreement} />
        ))}
      </Table>
      <hr className="my-3" />
    </div>
  )
}
