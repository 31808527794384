import * as React from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { PackageWholesaleProductFormInputs } from '@modules/package-wholesale/products/create/modal'
import { IconWithText } from '@components/icon-with-text'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { Col } from 'reactstrap'
import { ProductOptionsRow } from '@components/products/products-options-row'

interface Props {
  options: CustomReactSelectOption[]
  withDescription?: boolean
  allowDuplicates?: boolean
}

export const ProductOptions = ({ options, withDescription, allowDuplicates }: Props): React.ReactNode => {
  const { control } = useFormContext<PackageWholesaleProductFormInputs>()

  const { append, fields, remove, update } = useFieldArray({ control, name: 'options' })

  const handleAppend = (kind: CustomReactSelectOption | null = null) => {
    append({ kind, price_brutto: '', description: '' })
  }

  const addedOptions = useWatch({ control, name: 'options' })

  const notAddedOptions = options.filter(
    availableOption => !addedOptions.some(addedOption => availableOption.value === addedOption.kind?.value),
  )

  React.useEffect(() => {
    if (!fields.length) {
      handleAppend()
    }
  }, [fields])

  const canAddNextOne = !!addedOptions.at(-1)?.kind && (!!notAddedOptions.length || allowDuplicates)

  const isRemovable = (index: number) => {
    const option = addedOptions[index]
    if (!option) return false

    return !!option.kind?.value || !!option.description || !!option.price_brutto
  }

  const handleRemove = (index: number) => {
    if (addedOptions.length === 1) {
      update(index, { kind: null, description: '', price_brutto: '' })
    } else {
      remove(index)
    }
  }

  return (
    <Col>
      {fields.map((field, index) => (
        <ProductOptionsRow
          availableOptions={allowDuplicates ? options : notAddedOptions}
          key={field.id}
          index={index}
          isRemovable={isRemovable(index)}
          onDelete={handleRemove}
          withDescription={withDescription}
        />
      ))}

      {canAddNextOne && (
        <IconWithText
          flexClassName="d-block"
          icon="uil-plus font-16 lh-initial"
          text="Dodaj kolejną opcję dodatkową"
          textClass="font-12 text-semi-strong"
          wrapperClassNames="text-secondary"
          onClick={() => handleAppend()}
        />
      )}
    </Col>
  )
}
