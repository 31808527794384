import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { EntranceTicketPriceInformationTooltip } from '@modules/subscription/options/subscription-entrance-ticket/entrance-ticket-price-information-tooltip'
import { Row } from 'reactstrap'

export const SubscriptionProductOptions = (): React.ReactNode => (
  <Row className="mx-0">
    <FormInput
      label="Cena brutto (zmiany lokalizacji)"
      name="code_localization_convert_price_brutto"
      colSize={4}
      type="currency"
    />
    <FormInput label="Cena brutto (zmiany danych)" name="change_client_data_price_brutto" colSize={4} type="currency" />
    <FormInput
      label={
        <span>
          Cena brutto (biletu do Zoo)
          <EntranceTicketPriceInformationTooltip />
        </span>
      }
      name="zoo_borysew_tickets_single_price_brutto"
      colSize={4}
      type="currency"
    />
    <FormInput
      label={
        <span>
          Cena brutto (biletu do Suntago)
          <EntranceTicketPriceInformationTooltip />
        </span>
      }
      name="suntago_tickets_single_price_brutto"
      colSize={4}
      type="currency"
    />
  </Row>
)
