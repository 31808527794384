import * as React from 'react'
import { EntranceTicketPriceInformationTooltip } from '@modules/subscription/options/subscription-entrance-ticket/entrance-ticket-price-information-tooltip'
import { formatPriceShort } from '@helpers/utils'
import { nettoToBrutto, TaxValue } from '@helpers/price'
import { OptionWithAmount } from '@components/option-with-amount'
import { useFormContext, useWatch } from 'react-hook-form'

interface Props {
  checkboxName: string
  inputName: string
  colSize?: number
  checkboxTitle: string
  tax: TaxValue
}

export const PackageTicketEntrance = ({
  checkboxTitle,
  checkboxName,
  inputName,
  colSize = 12,
  tax,
}: Props): React.ReactNode => {
  const { control } = useFormContext()
  const [price, isChecked] = useWatch({ control, name: [inputName, checkboxName] })

  return (
    <OptionWithAmount
      colSize={colSize}
      optionFieldName={checkboxName}
      optionLabel={
        <span>
          {checkboxTitle}
          <EntranceTicketPriceInformationTooltip />
        </span>
      }
      amountInputProps={{
        disabled: !isChecked,
        name: inputName,
        placeholder: 'Cena netto',
        step: '0.01',
        type: 'currency',
      }}
    >
      <small className="text-nowrap font-11">
        {price ? `${formatPriceShort(nettoToBrutto(price, tax))} brutto` : '- zł brutto'}
      </small>
    </OptionWithAmount>
  )
}
