import * as React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { RootState, useAppDispatch, useAppSelector } from '@store/index'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { subscriptionCreate } from '@api/subscription'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import {
  ReceptionBookingCheckStep,
  ReceptionBookingCheckSteps,
  showStepError,
} from '@modules/reception/common/reception-booking-check-steps'
import { SubscriptionCreateFormClientData } from '@modules/subscription/create/steps/client-data'
import { SubscriptionCreateFormParams } from '@modules/subscription/create/steps/params'
import { SubscriptionCreateFormInformation } from '@modules/subscription/create/steps/information'
import { SaveButton } from '@hyper/button'
import { useFormRequest } from '@components/hooks/use-api-request'
import { ClientDetails, ClientUser } from '@models/clients'
import { BaseModalProps } from '@components/modals/types'
import { InvoiceType } from '@models/promotions'
import { parseISODate } from '@helpers/date-helper'
import { startOfToday } from 'date-fns'
import { SubscriptionCreateFormSummary } from '@modules/subscription/create/steps/summary'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'
import { Form } from '@hyper/forms/form'
import { SubscriptionType } from '@modules/subscription/models'
import { useModal } from '@components/modals/use-modal'

interface Props extends BaseModalProps {
  label: string
  type: SubscriptionType
  client?: ClientDetails
}

export interface SubscriptionCreateFormInputs {
  client: ClientUser | undefined
  clients: ClientUser[]
  invoice_company: string
  invoice_street: string
  invoice_postcode: string
  invoice_city: string
  invoice_nip: string
  invoice_type: InvoiceType
  product: string
  expire_after: Date
  auto_cancel_after_date: Date | null
  required_payment_date: Date | null
  seller: CustomReactSelectOption | undefined
  source_marketing: CustomReactSelectOption | undefined
  promotion_marketing: CustomReactSelectOption | undefined
  recommender_client: number | null
  allow_subscription_code_localization_convert: boolean
  allow_subscription_code_localization_convert_price_brutto: CustomReactSelectOption | null
  allow_change_client_data: boolean
  allow_change_client_data_price_brutto: CustomReactSelectOption | null
  with_zoo_borysew_tickets: boolean
  zoo_borysew_tickets_packs_amount: number
  zoo_borysew_tickets_price_brutto: CustomReactSelectOption | null
  with_suntago_tickets: boolean
  suntago_tickets_packs_amount: number
  suntago_tickets_price_brutto: CustomReactSelectOption | null
}

const steps: ReceptionBookingCheckStep[] = [
  {
    step: 1,
    description: <>Dane klienta</>,
    fields: [
      'name',
      'phone',
      'street',
      'email',
      'postcode',
      'city',
      'invoice_nip',
      'invoice_company',
      'invoice_street',
      'invoice_postcode',
      'invoice_city',
    ],
  },
  {
    step: 2,
    description: <>Parametry sprzedaży</>,
    fields: ['product', 'deposit_amount', 'expire_after'],
  },
  {
    step: 3,
    description: <>Informacje handlowe</>,
    fields: ['source_marketing', 'promotion_marketing', 'seller', 'recommender_client'],
  },
  {
    step: 4,
    description: 'Podsumowanie sprzedaży',
  },
]

export const SubscriptionCreateModal: React.FC<Props> = ({ label, type, toggleIsVisible, client }) => {
  const [step, setStep] = React.useState(1)

  const expireAfter = useAppSelector(
    (state: RootState) => state.subscriptionState.appData.default_subscription_expire_after,
  )
  const dispatch = useAppDispatch()

  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal()
  const [showSubscriptionDetails] = useModal('SubscriptionDetailsModal')

  const methods = useForm<SubscriptionCreateFormInputs>({
    defaultValues: {
      invoice_type: 'company',
      expire_after: parseISODate(expireAfter) || startOfToday(),
      allow_change_client_data: false,
      allow_subscription_code_localization_convert: false,
      with_zoo_borysew_tickets: false,
      zoo_borysew_tickets_packs_amount: 1,
      suntago_tickets_packs_amount: 1,
      clients: [],
      client,
      ...client?.profile,
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: SubscriptionCreateFormInputs) => {
    try {
      const subscriptionDetails = await subscriptionCreate(payload, type)
      showConfirmationPackageCreation(() => showSubscriptionDetails(null, { subscription: subscriptionDetails }))

      toggleIsVisible()
      dispatch(updateSubscriptionDetails(subscriptionDetails))
    } catch (error) {
      showStepError(setStep, steps, error)

      throw error
    }
  }, methods.setError)

  const handlePrevStep = () => setStep(curr => Math.max(1, curr - 1))
  const handleNextStep = () => setStep(curr => curr + 1)

  const data = useWatch({ control: methods.control })

  const isNextStepDisabled = React.useMemo(() => {
    if (step === 1) return !data.client
    if (step === 2) return !data.product
  }, [data, step])

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>{label}</ModalHeader>
      <ReceptionBookingCheckSteps step={step} steps={steps} />
      <ModalBody className="pb-0">
        {step === 1 && <SubscriptionCreateFormClientData type={type} initialClient={client} />}
        {step === 2 && <SubscriptionCreateFormParams type={type} />}
        {step === 3 && <SubscriptionCreateFormInformation />}
        {step === 4 && <SubscriptionCreateFormSummary />}
      </ModalBody>

      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6} className="p-0">
            {step !== 1 && (
              <Button onClick={handlePrevStep} className="btn btn-light">
                Wstecz
              </Button>
            )}
          </Col>
          <Col md={6} className="text-right p-0">
            {step === 4 ? (
              <SaveButton
                role="submit"
                labelSaving="Sprzedawanie..."
                label="Sprzedaj"
                className="btn btn-green"
                isSaving={isLoading}
              />
            ) : (
              <Button onClick={handleNextStep} className="btn btn-green" role="next-step" disabled={isNextStepDisabled}>
                Następny krok
              </Button>
            )}
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
