import * as React from 'react'
import { FormSelect } from '@hyper/forms/form-select'
import { createSelectOption, formatPriceShort } from '@helpers/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { useFormContext } from 'react-hook-form'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { usePackageAppData } from '@modules/package/hooks/use-package-app-data'
import { PackageCreateFormInputs } from '@modules/package/create/modal'
import { usePackageAccommodationCodes } from '@modules/package/create/steps/params/use-package-accommodation-codes'

export const PackageCreateFormParamsDeposit = (): React.ReactNode => {
  const appData = usePackageAppData()
  const { deposit_payment_deadline } = useAppData()
  const { setValue, getValues } = useFormContext<PackageCreateFormInputs>()

  const depositOptions = appData.deposit_amount.map(deposit => createSelectOption(formatPriceShort(deposit), deposit))

  const depositPaymentDeadlineOptions = [
    createSelectOption('Domyślne działanie', ''),
    ...deposit_payment_deadline.map(([value, label]) => createSelectOption(label, value)),
  ]

  const { hasApartmentCodes, hasHouseCodes } = usePackageAccommodationCodes(getValues())

  useDidUpdateEffect(() => {
    if (!hasApartmentCodes) setValue('deposit_amount', null)
    if (!hasHouseCodes) setValue('deposit_house_amount', null)
  }, [hasApartmentCodes, hasHouseCodes])

  return (
    <div className="d-flex align-items-center w-100">
      <strong className="col-3">Kaucja</strong>
      <div className="col-9 d-flex align-items-center flex-wrap px-0">
        <FormSelect
          options={depositOptions}
          name="deposit_house_amount"
          formPlainProps={{ colSize: 4 }}
          label="Kwota kaucji domek"
          selectProps={{ isDisabled: !hasHouseCodes }}
        />

        <FormSelect
          options={depositOptions}
          name="deposit_amount"
          formPlainProps={{ colSize: 4 }}
          label="Kwota kaucji apartament"
        />
        <FormSelect
          options={depositPaymentDeadlineOptions}
          name="deposit_payment_deadline"
          formPlainProps={{ colSize: 4 }}
          label="Termin wpłaty kaucji"
        />
      </div>
    </div>
  )
}
