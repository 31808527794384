import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { SpinInput } from '@components/spin-input'
import { asDecimal, formatPriceShort } from '@helpers/utils'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { FormPlain } from '@hyper/forms'
import { CustomInput } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface Props {
  kind: string
  isEditDisabled?: boolean
  price?: string
  priceOptions?: CustomReactSelectOption[]
}

export const EntranceTicketOption: React.FC<Props> = ({ kind, price, isEditDisabled, priceOptions }) => {
  const { register, control, setValue } = useFormContext()

  const [ticketName, amountName, priceName] = [
    `with_${kind}_tickets`,
    `${kind}_tickets_packs_amount`,
    `${kind}_tickets_price_brutto`,
  ]

  const [ticketSelected, amount, selectedPrice] = useWatch({ control, name: [ticketName, amountName, priceName] })
  const singleTicketPrice = priceOptions?.length ? selectedPrice?.value : price

  const entranceTicketsPrice = asDecimal(amount || 1)
    .mul(singleTicketPrice || 0)
    .toString()

  useDidUpdateEffect(() => {
    if (!ticketSelected) {
      setValue(amountName, 1)
      setValue(priceName, null)
    }

    if (ticketSelected && priceOptions?.length) {
      setValue(priceName, priceOptions?.[0])
    }
  }, [ticketSelected])

  return (
    <div className="d-flex w-100">
      <FormPlain name={ticketName} colSize={4}>
        <CustomInput
          disabled={isEditDisabled}
          className="mt-2"
          type="checkbox"
          id={ticketName}
          label={
            <span className="text-nowrap">
              Bilety do {kind == 'zoo_borysew' ? 'ZOO Borysew' : 'Suntago'}{' '}
              {singleTicketPrice ? formatPriceShort(entranceTicketsPrice) : ''}
              <IconWithTooltip
                icon="uil-exclamation-circle ml-1"
                tooltipId="password-protected-tooltip-id"
                tooltipPlacement="right"
                tooltipMessage={
                  <span className="font-11 text-left">
                    <span>Zestaw składa się z 4 sztuk.</span> <br />
                    Cena obliczana na podstawie wybranej ilości zestawów.
                  </span>
                }
                color="text-secondary"
              />
            </span>
          }
          {...extractInnerRef(register(ticketName))}
        />
      </FormPlain>
      {priceOptions?.length && (
        <FormSelect
          options={priceOptions}
          name={priceName}
          formPlainProps={{ colStyle: { maxWidth: 120 }, colClassName: 'px-0 mr-2' }}
          selectProps={{ isDisabled: !ticketSelected, placeholder: 'Cena biletu' }}
        />
      )}
      <div>
        <SpinInput
          inputName={amountName}
          inputProps={{
            min: 1,
            max: 100,
            disabled: !ticketSelected,
            style: { width: 70 },
          }}
        />
      </div>
    </div>
  )
}
