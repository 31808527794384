import { createSelectOption } from '@helpers/utils'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { useAppData } from '@components/hooks/use-app-data'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Response {
  productSetOptions: CustomReactSelectOption[]
  clientKindOptions: CustomReactSelectOption[]
  depositPaymentDeadlineOptions: CustomReactSelectOption[]
  accommodationTypeOptions: CustomReactSelectOption[]
  depositOptions: CustomReactSelectOption[]
  subscriptionCodeNameKindOptions: CustomReactSelectOption[]
}

export const useSubscriptionProductCreateFormOptions = (): Response => {
  const { client_kinds, subscription_product_sets, subscription_code_name_kinds } = useSubscriptionAppData()
  const { deposit_payment_deadline } = useAppData()

  const subscriptionCodeNameKindOptions = subscription_code_name_kinds.map(([value, label]) =>
    createSelectOption(label, value),
  )

  const productSetOptions = subscription_product_sets.map(set =>
    createSelectOption(set.name, set.id, set.status === 'unavailable', set),
  )

  const clientKindOptions = client_kinds.map(([value, label]) => createSelectOption(label, value))
  const depositPaymentDeadlineOptions = [
    createSelectOption('Domyślne działanie', ''),
    ...deposit_payment_deadline.map(([value, label]) => createSelectOption(label, value)),
  ]

  const accommodationTypeOptions = [createSelectOption('Domek', 1), createSelectOption('Apartament', 2)]

  const depositOptions = [
    createSelectOption('0 zł', 0),
    createSelectOption('500 zł', 500),
    createSelectOption('750 zł', 750),
    createSelectOption('899 zł', 899),
    createSelectOption('999 zł', 999),
    createSelectOption('1000 zł', 1000),
  ]

  return {
    productSetOptions,
    clientKindOptions,
    depositPaymentDeadlineOptions,
    accommodationTypeOptions,
    depositOptions,
    subscriptionCodeNameKindOptions,
  }
}
