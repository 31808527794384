import * as React from 'react'
import { Alert, Card, CardBody } from 'reactstrap'
import { ProductCardTitle } from '@components/products/product-card-title'
import { HrWorkerDetails } from '@modules/hr/workers/models'
import { WorkerDocumentCard } from '@modules/hr/workers/details/documents/worker-document-card'
import { sortObjectListByDate } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  workerDetails: HrWorkerDetails
}

export const HrWorkerDetailsDocuments = React.forwardRef(
  ({ workerDetails }: Props, ref: React.RefObject<HTMLElement>): React.ReactNode => {
    const documents = sortObjectListByDate(workerDetails.hr_documents, 'created')

    return (
      <Card innerRef={ref}>
        <CardBody>
          <ProductCardTitle title="Dokumenty">
            {workerDetails.high_priority && (
              <div className="ml-auto">
                <Alert className="py-1 px-2 alert-warning d-inline-block mr-2">
                  <IconWithText
                    icon="uil-info-circle font-12 mr-2 pulse-scale"
                    text="Oczekujące dokumenty do weryfikacji"
                    textClass="font-11 fw-semi-bold"
                  />
                </Alert>
              </div>
            )}
          </ProductCardTitle>

          {documents.map(document => (
            <WorkerDocumentCard key={document.id} document={document} workerDetails={workerDetails} />
          ))}

          {!documents.length && <p>Lista jest pusta</p>}
        </CardBody>
      </Card>
    )
  },
)
