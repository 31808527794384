import * as React from 'react'
import { ButtonWithIcon, ButtonWithIconProps } from '@components/button-with-icon'

interface Props extends Partial<ButtonWithIconProps> {
  url: string
}

export const PrintButton = ({ url, ...props }: Props): React.ReactNode => {
  const handlePrint = () => {
    const newWindow = window.open(url, '_blank')
    if (newWindow) newWindow.onload = newWindow.print
  }

  return (
    <ButtonWithIcon
      btnClass="d-block ml-auto"
      color="light"
      {...props}
      icon="uil-print"
      text="Drukuj dokument"
      handleClick={handlePrint}
    />
  )
}
