import * as React from 'react'
import { ReceptionBookingDetails } from '@models/reception'
import { ReservationStatus } from '@modules/reservations/details/base-data/booking/status-label/reservation-status'
import { ReservationDataResort } from '@modules/reservations/details/base-data/booking/reservation-data-resort'
import { ReservationDataStayDates } from '@modules/reservations/details/base-data/booking/reservation-data-stay-dates'
import { ReservationLocalData } from '@modules/reservations/details/reservation-local-data'
import { IconWithText } from '@components/icon-with-text'
import { ReservationGuestContactData } from '@modules/reservations/details/base-data/guest/reservation-guest-contact-data'
import { ReservationBaseDataFixedAddButton } from '@modules/reservations/details/reservation-base-data-fixed-add-button'
import { CopyToClipboardTooltip } from '@components/copy-to-clipboard-tooltip'
import { NavigationPath } from '@models/routes'
import { getDashboardAppUrl } from '@helpers/utils'

interface Props {
  dataRef: React.RefObject<HTMLElement>
  booking: ReceptionBookingDetails
}

export const ReservationBaseDataFixedPreview = ({ dataRef, booking }: Props): React.ReactNode => {
  const [isOutOfScreen, setIsOutOfScreen] = React.useState(false)

  React.useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      setIsOutOfScreen(!entries[0].isIntersecting)
    }

    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 })

    if (dataRef.current) observer.observe(dataRef.current)
    return () => {
      if (dataRef.current) observer.unobserve(dataRef.current)
    }
  }, [dataRef.current])

  return (
    <div
      className="position-sticky w-100"
      style={{
        zIndex: 2,
        top: 80,
        height: 0,
        opacity: isOutOfScreen ? 1 : 0,
        transform: isOutOfScreen ? 'translateY(40px)' : 'translateY(-100px)',
        transition: 'transform 200ms linear, opacity 100ms linear',
      }}
    >
      <div className="py-1 mx-n2 bg-white row font-11 box-shadow-bottom">
        <div className="col-3">
          <ReservationStatus booking={booking} />
        </div>
        <div className="col-1 mr-3">
          <IconWithText
            wrapperClassNames="text-secondary fw-semi-bold"
            icon="uil-calender font-14 align-self-start"
            text={
              <div>
                <div className="font-13 text-nowrap">Dane rezerwacji</div>
                <CopyToClipboardTooltip
                  placement="top"
                  className="text-default font-14 mt-1 font-weight-bold px-0"
                  value={booking.reservation_number}
                  tooltipId={`booking-number-copy-${booking.id}`}
                >
                  {booking.reservation_number}
                </CopyToClipboardTooltip>
              </div>
            }
          />
        </div>

        <div className="col-3 d-flex">
          <div>{isOutOfScreen && <ReservationLocalData booking={booking} />}</div>
          <div className="d-flex flex-column ml-3">
            <ReservationDataResort resortId={booking.resort_id} />
            <ReservationDataStayDates booking={booking} />
          </div>
        </div>

        <div className="border-left pl-3 d-flex">
          <IconWithText
            wrapperClassNames="text-secondary fw-semi-bold"
            textClass="align-self-start"
            icon="uil-user font-14 align-self-start"
            text={
              <div>
                <span className="font-13 text-nowrap">Dane gościa</span>
                <a
                  href={getDashboardAppUrl(
                    NavigationPath.CrmClientsWitParams.replace(':client_id', String(booking.client_id)),
                  )}
                  target="_blank"
                >
                  <strong className="text-default font-14 d-block mt-1">{booking.name}</strong>
                </a>
              </div>
            }
          />
          <div className="ml-4">
            <ReservationGuestContactData booking={booking} />
          </div>
        </div>
        <ReservationBaseDataFixedAddButton booking={booking} />
      </div>
    </div>
  )
}
