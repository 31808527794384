import * as React from 'react'
import { SubscriptionDetails, SubscriptionOptionKind } from '@modules/subscription/models'
import { DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { getById } from '@helpers/utils'
import { SubscriptionTypeStrategyOption } from '@store/slices/subscription-slice'
import { SubscriptionDetailsInformationStrategyOptions } from '@modules/subscription/details/options/strategy-options'
import { SubscriptionDetailsInformationProductOptions } from '@modules/subscription/details/options/product-options'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsInformationOptionsAdd: React.FC<Props> = ({ subscriptionDetails }) => {
  const { crud_permissions } = useSubscriptionTypeStrategyProvider(subscriptionDetails.type)
  const { subscription_product_sets } = useSubscriptionAppData()
  const user = useAuthenticatedUser()

  if (!user.hasPerm(crud_permissions.update)) return null

  const canAddOptionsAfterPayment = user.hasPerm(UserPermission.SubscriptionCanAddOptionsAfterPayment)
  const canAddZooTickets = user.hasPerm(UserPermission.SubscriptionCanAddZooTickets)
  const canAddSuntagoTickets = user.hasPerm(UserPermission.SubscriptionCanAddSuntagoTickets)

  const isTypeWholesale = subscriptionDetails.type === 'WHOLESALE'
  const isInitial = subscriptionDetails.status === 'initial'

  const hasOption = (kind: SubscriptionOptionKind) => subscriptionDetails.options.some(row => row.kind === kind)
  const isExtensionAvailable = ['brown', 'gray', 'silver'].some(kind => subscriptionDetails.kind === kind)

  const getOptions = (
    params: Partial<Record<SubscriptionTypeStrategyOption, boolean>> = {},
  ): Record<SubscriptionTypeStrategyOption, boolean> => {
    const additionalConditions = {
      code_kind_convert: false,
      single_change_date: false,
      upgrade_to_purple: false,
      zoo_borysew: false,
      suntago: false,
      expiration_extend: false,
      single_localization: false,
      localization: false,
      single_change_data: false,
      change_data: false,
      ...params,
    }

    return {
      change_data:
        (isInitial || canAddOptionsAfterPayment) && !hasOption('client_data') && additionalConditions.change_data,
      single_change_data: !hasOption('client_data') && additionalConditions.single_change_data,
      localization: !hasOption('localization_unlimited') && additionalConditions.localization,
      single_localization: !hasOption('localization_unlimited') && additionalConditions.single_localization,
      code_kind_convert: additionalConditions.code_kind_convert,
      single_change_date: additionalConditions.single_change_date,
      upgrade_to_purple: additionalConditions.upgrade_to_purple,
      zoo_borysew: canAddZooTickets && (isInitial || canAddOptionsAfterPayment) && additionalConditions.zoo_borysew,
      suntago: canAddSuntagoTickets && (isInitial || canAddOptionsAfterPayment) && additionalConditions.suntago,
      expiration_extend: isTypeWholesale && isExtensionAvailable && additionalConditions.expiration_extend,
    }
  }

  const selectedSet = getById(subscription_product_sets, subscriptionDetails.subscription_product_set)
  const hasProductOptions = selectedSet?.options_version === 2

  return (
    <div className="text-right">
      <UncontrolledButtonDropdown direction="down" group={true}>
        <DropdownToggle color="green" caret={true} className="btn-sm">
          Dodaj
        </DropdownToggle>
        <DropdownMenu right={true}>
          {hasProductOptions ? (
            <SubscriptionDetailsInformationProductOptions
              subscriptionDetails={subscriptionDetails}
              getOptions={getOptions}
            />
          ) : (
            <SubscriptionDetailsInformationStrategyOptions
              subscriptionDetails={subscriptionDetails}
              getOptions={getOptions}
            />
          )}
        </DropdownMenu>
      </UncontrolledButtonDropdown>{' '}
    </div>
  )
}
