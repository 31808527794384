import * as React from 'react'
import { FormInput } from '@hyper/forms'
import { Row } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { SubscriptionCreateFormInputs } from '@modules/subscription/create/modal'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import * as R from 'ramda'
import { SubscriptionOptionKindPrice, SubscriptionProduct, SubscriptionType } from '@modules/subscription/models'
import { formatPriceShort, getById } from '@helpers/utils'
import { add, startOfToday } from 'date-fns'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { SubscriptionOptionsForm } from '@modules/subscription/create/options/options-form'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { SubscriptionCreateFormAdditionalClient } from '@modules/subscription/create/steps/additional-client'
import { SubscriptionProductOptionsForm } from '@modules/subscription/create/options/product-options'
import { useSubscriptionCreateFormPrice } from '@modules/subscription/create/steps/use-subscription-create-form-price'

interface Props {
  type: SubscriptionType
}

export const SubscriptionCreateFormParams: React.FC<Props> = ({ type }) => {
  const { control, setValue } = useFormContext<SubscriptionCreateFormInputs>()
  const appData = useSubscriptionAppData()
  const grouper = R.groupBy<SubscriptionProduct, string>(R.prop('client_kind_display'))
  const productGroups = Object.values(
    grouper(appData.products.filter(row => row.status === 'available' && row.type == type)),
  )

  const selectedProductId = useWatch({ control, name: 'product' })
  const selectedProduct = getById(appData.products, selectedProductId)

  const { allow_add_additional_clients } = useSubscriptionTypeStrategyProvider(type)
  const { totalPrice } = useSubscriptionCreateFormPrice(selectedProduct)

  useDidUpdateEffect(() => {
    setValue('allow_subscription_code_localization_convert_price_brutto', null)
    setValue('allow_change_client_data_price_brutto', null)
    setValue('allow_subscription_code_localization_convert', false)
    setValue('allow_change_client_data', false)
    setValue('with_zoo_borysew_tickets', false)
    setValue('zoo_borysew_tickets_price_brutto', null)
    setValue('zoo_borysew_tickets_packs_amount', 1)
    setValue('with_suntago_tickets', false)
    setValue('suntago_tickets_price_brutto', null)
    setValue('suntago_tickets_packs_amount', 1)

    if (!selectedProduct) {
      setValue('clients', [])
      return
    }

    if (selectedProduct.default_expire_years) {
      setValue('expire_after', add(startOfToday(), { years: selectedProduct.default_expire_years }))
    }
  }, [selectedProduct])

  const prices: SubscriptionOptionKindPrice = {
    zoo_borysew: selectedProduct?.zoo_borysew_tickets_single_price_brutto ?? '',
    suntago: selectedProduct?.suntago_tickets_single_price_brutto ?? '',
    localization: selectedProduct ? selectedProduct.code_localization_convert_price_brutto : '',
    client_data: selectedProduct ? selectedProduct.change_client_data_price_brutto : '',
  }

  const productSet = getById(appData.subscription_product_sets, selectedProduct?.subscription_product_set)
  const hasProductOptions = selectedProduct?.type === 'DEFAULT' && productSet?.options_version === 2

  return (
    <Row>
      <FormInput type="select" label="Produkt" colSize={6} name="product" registerParams={{ valueAsNumber: true }}>
        <option value={undefined}>wybierz</option>
        {productGroups.map((products: SubscriptionProduct[]) => (
          <optgroup key={products[0].client_kind_display} label={products[0].client_kind_display}>
            {products.map(product => (
              <option value={product.id} key={product.id}>
                {product.name}
              </option>
            ))}
          </optgroup>
        ))}
      </FormInput>

      {(allow_add_additional_clients || selectedProduct?.allow_add_second_client) && (
        <SubscriptionCreateFormAdditionalClient />
      )}

      <div className="w-100">
        {hasProductOptions ? (
          <SubscriptionProductOptionsForm product={selectedProduct} />
        ) : (
          <SubscriptionOptionsForm type={type} isEditDisabled={!selectedProduct} prices={prices} />
        )}
      </div>

      <div className="w-100">
        <div className="bg-grey p-2" style={{ marginInline: -4 }}>
          <div className="fw-semi-bold font-16 text-right">Cena brutto {formatPriceShort(totalPrice)}</div>
        </div>
      </div>
    </Row>
  )
}
