import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { FormInput } from '@hyper/forms'
import { Row } from 'reactstrap'
import { SubscriptionProductCreateFormInputs } from '@modules/subscription/products/create/modal'
import { UserPermission } from '@models/dashboard'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'
import { FormSelect } from '@hyper/forms/form-select'
import { packageWholesaleProductsStatusOptions } from '@modules/package-wholesale/products/list/filter'
import { SubscriptionTypeStrategy } from '@store/slices/subscription-slice'
import { SubscriptionProductCreateFormCodeFeatures } from '@modules/subscription/products/create/form-code-features'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormCheckbox } from '@hyper/forms/form-checkbox'
import { useSubscriptionProductCreateFormOptions } from '@modules/subscription/products/create/use-form-options'
import { SubscriptionType } from '@modules/subscription/models'
import { SubscriptionProductOptions } from '@modules/subscription/products/create/options/options'
import { DefaultSubscriptionProductOptions } from '@modules/subscription/products/create/options/default-subscription-options'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'

interface Props {
  subscriptionTypeStrategy: SubscriptionTypeStrategy
  defaultsDaysOptions: CustomReactSelectOption[]
  subscriptionType: SubscriptionType
}

export const SubscriptionProductCreateForm: React.FC<Props> = ({ subscriptionTypeStrategy, subscriptionType }) => {
  const { control, setValue } = useFormContext<SubscriptionProductCreateFormInputs>()

  const user = useAuthenticatedUser()
  const { subscriptionKindOptions, getSubscriptionKindVersionOptions } = useSubscriptionKinds()

  const [kind, selectedSet, accommodationType] = useWatch({
    control,
    name: ['kind', 'subscription_product_set', 'accommodation_type'],
  })
  const subscriptionKindVersionOptions = getSubscriptionKindVersionOptions(kind)

  const {
    clientKindOptions,
    accommodationTypeOptions,
    depositOptions,
    depositPaymentDeadlineOptions,
    subscriptionCodeNameKindOptions,
    productSetOptions,
  } = useSubscriptionProductCreateFormOptions()

  const isDefaultType = subscriptionType === 'DEFAULT'
  const canChangeSet = !subscriptionType || isDefaultType

  const hasOwnOptions = selectedSet?.context?.options_version === 2

  const isHouseAccommodationType = ACCOMMODATION_TYPES.HOUSES.includes(accommodationType?.value)

  useDidUpdateEffect(() => {
    if (!isHouseAccommodationType) setValue('deposit_house_amount', null)
  }, [isHouseAccommodationType])

  return (
    <>
      <Row>
        <FormInput label="Nazwa produktu" name="name" colSize={9} />
        <FormSelect
          name="status"
          label="Status"
          options={packageWholesaleProductsStatusOptions}
          formPlainProps={{ colSize: 3 }}
        />
        <hr className="w-100 mt-1" />
        {canChangeSet && (
          <FormSelect
            selectProps={{
              isOptionDisabled: (option: CustomReactSelectOption) => !!option.disabled,
            }}
            options={productSetOptions}
            name="subscription_product_set"
            label="Zestaw"
            formPlainProps={{ colSize: 6 }}
          />
        )}

        <FormSelect
          name="client_kind"
          options={clientKindOptions}
          label="Rodzaj klienta"
          formPlainProps={{ colSize: 6 }}
        />

        <FormInput label="Prefiks kodów" name="prefix" colSize={4} />

        <FormSelect
          options={subscriptionKindOptions}
          name="kind"
          label="Rodzaj pakietu"
          formPlainProps={{ colSize: 4 }}
        />
        {!!subscriptionKindVersionOptions.length && (
          <FormSelect
            options={subscriptionKindVersionOptions}
            name="kind_version"
            label="Wersja rodzaju"
            formPlainProps={{ colSize: 4 }}
          />
        )}
        <Row className="mx-0 w-100">
          <FormInput
            label="Kody morze"
            name="local_sea"
            colSize={3}
            registerParams={{ valueAsNumber: true }}
            type="number"
          />
          <FormInput
            label="Kody góry"
            name="local_mountains"
            colSize={3}
            registerParams={{ valueAsNumber: true }}
            type="number"
          />
          {subscriptionTypeStrategy.has_universal_localization_code && (
            <FormInput
              label="Kody uniwersalne"
              name="local_universal"
              colSize={3}
              registerParams={{ valueAsNumber: true }}
              type="number"
            />
          )}
          <FormSelect
            options={accommodationTypeOptions}
            name="accommodation_type"
            label="Zakwaterowanie"
            formPlainProps={{ colSize: 3 }}
          />
        </Row>

        <hr className="w-100 mt-1" />

        <FormSelect
          options={subscriptionCodeNameKindOptions}
          name="subscription_code_name_kind"
          label="Rodzaj kodów"
          formPlainProps={{ colSize: 4 }}
        />

        {subscriptionTypeStrategy.allow_change_defaults_days && (
          <FormInput name="defaults_days" label="Długość pobytu (doby)" colSize={4} type="number" />
        )}
        <FormInput
          disabled={!user.hasPerm(UserPermission.SubscriptionCanSetExpireAfter)}
          label="Data ważności w latach"
          name="default_expire_years"
          type="number"
          colSize={4}
        />
        <hr className="w-100 mt-1" />
        <FormInput label="Cena brutto" name="price_brutto" colSize={2} type="currency" />
        <FormSelect
          options={depositOptions}
          name="deposit_amount"
          formPlainProps={{ colSize: 3 }}
          label="Kaucja apartament"
        />
        <FormSelect
          selectProps={{ isDisabled: !isHouseAccommodationType }}
          options={depositOptions}
          name="deposit_house_amount"
          formPlainProps={{ colSize: 3 }}
          label="Kaucja domek"
        />
        <FormSelect
          name="deposit_payment_deadline"
          options={depositPaymentDeadlineOptions}
          label="Termin wpłaty kaucji"
          formPlainProps={{ colSize: 4 }}
        />
        <hr className="w-100 mt-1" />
        {hasOwnOptions ? <DefaultSubscriptionProductOptions /> : <SubscriptionProductOptions />}
      </Row>

      <hr className="mx-n2" />
      {subscriptionTypeStrategy.can_add_subscription_code_feature && (
        <SubscriptionProductCreateFormCodeFeatures subscriptionTypeStrategy={subscriptionTypeStrategy} />
      )}
      <FormCheckbox name="allow_add_second_client" label="Możliwość dodania współwłaściciela" className="mb-2" />
    </>
  )
}
