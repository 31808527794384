interface PackageAccommodationCodes {
  apartments_mountains: number
  apartments_sea: number
  apartments_universal: number

  houses_mountains: number
  houses_sea: number
  houses_universal: number
}

export const usePackageAccommodationCodes = <T extends PackageAccommodationCodes>(details?: T) => {
  const hasApartmentCodes = () => {
    if (details) return details.apartments_sea + details.apartments_mountains + details.apartments_universal > 0
    return false
  }

  const hasHouseCodes = () => {
    if (details) return details.houses_sea + details.houses_mountains + details.houses_universal > 0
  }

  return {
    hasApartmentCodes: hasApartmentCodes(),
    hasHouseCodes: hasHouseCodes(),
  }
}
