import * as React from 'react'
import { FormPlain } from '@hyper/forms'
import { CustomInput } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { SubscriptionOptionKindPrice, SubscriptionType } from '@modules/subscription/models'
import { useFormContext } from 'react-hook-form'
import { formatPriceShort } from '@helpers/utils'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { EntranceTicketOption } from '@modules/subscription/create/options/entrance-ticket-option'

interface Props {
  isEditDisabled?: boolean
  prices: SubscriptionOptionKindPrice
  type: SubscriptionType
}

export const SubscriptionOptionsForm = ({ isEditDisabled, prices, type }: Props): React.ReactNode => {
  const { register } = useFormContext()

  const { allow_options } = useSubscriptionTypeStrategyProvider(type)

  const hasOption =
    allow_options.includes('change_data') ||
    allow_options.includes('localization') ||
    allow_options.includes('zoo_borysew') ||
    allow_options.includes('suntago')

  if (!hasOption) return null

  return (
    <div className="mb-3">
      <h5 className="ml-2 font-13 mb-2">Opcje dodatkowe</h5>

      <hr className="w-100" />

      {allow_options.includes('change_data') && prices.client_data !== null && (
        <FormPlain name="allow_change_client_data" formGroupClassName="mb-0">
          <CustomInput
            disabled={isEditDisabled}
            type="checkbox"
            id="allow_change_client_data"
            label={`Zmiana danych (wielokrotna) ${prices.client_data ? formatPriceShort(prices.client_data) : ''}`}
            {...extractInnerRef(register('allow_change_client_data'))}
          />
        </FormPlain>
      )}
      {allow_options.includes('localization') && prices.client_data !== null && (
        <FormPlain name="allow_subscription_code_localization_convert" formGroupClassName="mb-0">
          <CustomInput
            disabled={isEditDisabled}
            className="mt-2"
            type="checkbox"
            id="allow_subscription_code_localization_convert"
            label={`Zmiana lokalizacji (wielokrotna) ${prices.localization ? formatPriceShort(prices.localization) : ''}`}
            {...extractInnerRef(register('allow_subscription_code_localization_convert'))}
          />
        </FormPlain>
      )}
      {allow_options.includes('zoo_borysew') && prices.zoo_borysew && (
        <EntranceTicketOption kind="zoo_borysew" price={prices.zoo_borysew} isEditDisabled={isEditDisabled} />
      )}
      {allow_options.includes('suntago') && prices.suntago && (
        <EntranceTicketOption kind="suntago" price={prices.suntago} isEditDisabled={isEditDisabled} />
      )}
    </div>
  )
}
