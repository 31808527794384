import * as React from 'react'
import { formatPriceShort } from '@helpers/utils'
import { IconWithText } from '@components/icon-with-text'
import { PackageSummarySectionDataRow } from '@modules/package/create/package-summary-section'
import { useFormContext } from 'react-hook-form'
import { SubscriptionCreateFormInputs } from '@modules/subscription/create/modal'
import { SubscriptionProduct } from '@modules/subscription/models'
import { useSubscriptionCreateFormPrice } from '@modules/subscription/create/steps/use-subscription-create-form-price'

interface Props {
  selectedProduct: SubscriptionProduct
}

export const SubscriptionCreateFormSummaryAdditionalOption = ({ selectedProduct }: Props): React.ReactNode => {
  const methods = useFormContext<SubscriptionCreateFormInputs>()

  const data = methods.getValues()
  const {
    clientDataChangeOptionPrice,
    localizationChangeOptionPrice,
    totalPrice,
    totalSuntagoTicketPrice,
    totalZooTicketPrice,
  } = useSubscriptionCreateFormPrice(selectedProduct)

  return (
    <>
      <PackageSummarySectionDataRow
        title="Termin płatności kaucji:"
        content={selectedProduct.deposit_payment_deadline_display}
        titleFlex="col-3"
        contentFlex="col-9"
      />
      <PackageSummarySectionDataRow
        title="Dodatkowe opcje:"
        content={
          <ul className="list-unstyled mb-0">
            {data.allow_change_client_data && (
              <li>
                <span>{formatPriceShort(clientDataChangeOptionPrice)}</span>
                <IconWithText
                  wrapperClassNames="ml-2"
                  text="możliwość zmiany danych"
                  textClass="font-weight-normal"
                  icon="font-16 lh-0 uil-check text-secondary"
                />
              </li>
            )}

            {data.allow_subscription_code_localization_convert && (
              <li>
                <span>{formatPriceShort(localizationChangeOptionPrice)}</span>
                <IconWithText
                  wrapperClassNames="ml-2"
                  text="możliwość zmiany lokalizacji"
                  textClass="font-weight-normal"
                  icon="font-16 lh-0 uil-check text-secondary"
                />
              </li>
            )}

            {data.with_zoo_borysew_tickets && (
              <li>
                <span>{formatPriceShort(totalZooTicketPrice)}</span>
                <IconWithText
                  wrapperClassNames="ml-2"
                  text="bilety wstępu do ZOO Borysew"
                  textClass="font-weight-normal"
                  icon="font-16 lh-0 uil-check text-secondary"
                />
              </li>
            )}

            {data.with_suntago_tickets && (
              <li>
                <span>{formatPriceShort(totalSuntagoTicketPrice)}</span>
                <IconWithText
                  wrapperClassNames="ml-2"
                  text="bilety wstępu do Suntago"
                  textClass="font-weight-normal"
                  icon="font-16 lh-0 uil-check text-secondary"
                />
              </li>
            )}
          </ul>
        }
        titleFlex="col-3"
        contentFlex="col-9"
      />
      <PackageSummarySectionDataRow
        title="Razem (produkt + opcje):"
        content={formatPriceShort(totalPrice)}
        titleFlex="col-3"
        contentFlex="col-9"
      />
    </>
  )
}
