import * as React from 'react'
import { Form } from '@hyper/forms/form'
import { ContentLoader } from '@components/content-loader'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { ReceptionBookingCheckSteps, showStepError } from '@modules/reception/common/reception-booking-check-steps'
import { SaveButton } from '@hyper/button'
import { useForm, useWatch } from 'react-hook-form'
import { BaseModalProps } from '@components/modals/types'
import { HrWorkerCreateMainData } from '@modules/hr/workers/create/steps/main-data'
import { HrAddressForm } from '@modules/hr/common/form/addresses'
import {
  HrHealthCareAndFinanceData,
  HrHealthcareAndFinanceForm,
} from '@modules/hr/common/form/hr-health-care-and-finance-data'
import { useHrWorkerCreateWizardDefaults } from '@modules/hr/workers/create/use-hr-worker-create-wizard-defaults'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { HrWorkerCreateFormSummaryStep } from '@modules/hr/workers/create/steps/hr-worker-summary-step'
import { agreementCreateSteps } from '@modules/hr/agreement/create/steps/steps'
import { useUpsertHrWorkerMutation } from '@api/agreements'
import { HrWorker, HrWorkerDetails } from '@modules/hr/workers/models'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { HrWorkerDataPreview } from '@modules/hr/workers/create/hr-worker-data-preview'
import { useHandleRtkQueryError } from '@components/hooks/use-handle-rtk-error'
import { extractSelectOptionsValues, noop } from '@helpers/utils'
import { HrAddress } from '@modules/hr/common/models'
import { useProductSaleConfirmationModal } from '@components/sale/use-product-sale-confirmation-modal'

const FIRST_STEP_OPTIONAL_FIELDS = ['second_name', 'phone', 'email']

export const HrWorkerCreateSteps = [
  ...[...agreementCreateSteps].slice(0, 3).map((data, index) => ({ ...data, step: index + 1 })),
  { step: 4, description: 'Podsumowanie' },
]

export interface HrWorkersCreateFormInputs {
  //personal data
  first_name: string
  second_name: string
  last_name: string
  email: string
  nationality: CustomReactSelectOption
  tax_id: string
  personal_id_number: string
  personal_id_number_type: CustomReactSelectOption | null
  phone: string
  birthday: Date | null

  //address data
  registered_street: string
  registered_street_house: string
  registered_street_apartment: string
  registered_city: CustomReactSelectOption | null
  registered_district: CustomReactSelectOption | null
  registered_postcode: string
  registered_post: CustomReactSelectOption | null
  registered_community: CustomReactSelectOption | null
  registered_province: CustomReactSelectOption | null
  street: string
  street_house: string
  street_apartment: string
  city: CustomReactSelectOption | null
  district: CustomReactSelectOption | null
  postcode: string
  post: CustomReactSelectOption | null
  community: CustomReactSelectOption | null
  province: CustomReactSelectOption | null

  // finanse and national health data
  national_health_fund_branch: string
  account_number: string
  bank_name: string
  tax_office: CustomReactSelectOption | null

  different_address: boolean

  selectedWorker: HrWorker | null
  isFormVisible: boolean
}

interface Props extends BaseModalProps {
  workerDetails: HrWorkerDetails
  onWorkerSuccessEdit?: () => void
}

export const HrWorkerCreateModal = ({ toggleIsVisible, workerDetails, onWorkerSuccessEdit }: Props): JSX.Element => {
  const [step, setStep] = React.useState(1)
  const { defaultValues } = useHrWorkerCreateWizardDefaults(workerDetails)

  const { addSuccessMessage } = useNotificationHook()
  const { showConfirmationPackageCreation } = useProductSaleConfirmationModal({
    title: 'Sukces',
    content: 'Dane pracownika zostały zaktualizowane. Wygenerowano nowy dokument.',
    detailsText: 'Przejdź do dokumentu',
    backText: 'Zamknij',
  })

  const methods = useForm<HrWorkersCreateFormInputs>({ defaultValues })

  const [upsertWorker, { error, isError, isLoading }] = useUpsertHrWorkerMutation()

  const handleError = React.useCallback(
    (key, message) => {
      showStepError(setStep, HrWorkerCreateSteps, error)
      methods.setError(key as any, message)
    },
    [error],
  )

  useHandleRtkQueryError(error, isError, handleError)

  const onSubmit = async (payload: HrWorkersCreateFormInputs) => {
    methods.clearErrors()
    try {
      if (workerDetails) {
        await upsertWorker({ url: workerDetails.urls.details, data: payload }).unwrap()
        showConfirmationPackageCreation(onWorkerSuccessEdit ?? noop)
      } else {
        await upsertWorker({ data: payload }).unwrap()
        addSuccessMessage('Sukces', 'Pracownik został dodany')
      }
      toggleIsVisible()
    } catch (error) {
      console.error(error)
    }
  }

  const handlePrevStep = () => {
    setStep(state => state - 1)
  }

  const handleNextStep = () => {
    setStep(state => state + 1)
  }

  const selectedWorker = useWatch({ control: methods.control, name: 'selectedWorker' })
  const formValues = methods.watch()

  const isNextStepDisabled = () => {
    const stepData = HrWorkerCreateSteps.find(el => el.step === step)

    if (!stepData?.fields) return true

    if (step === 1) {
      return stepData.fields.some(field => {
        if (FIRST_STEP_OPTIONAL_FIELDS.includes(field)) return false
        return !formValues[field]
      })
    }

    return false
  }

  const extractedFormValues = extractSelectOptionsValues(methods.getValues())

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ContentLoader isLoading={false}>
        <ModalHeader toggle={toggleIsVisible}>{workerDetails ? 'Edytuj pracownika' : 'Dodaj pracownika'}</ModalHeader>
        <ReceptionBookingCheckSteps step={step} steps={HrWorkerCreateSteps} />
        <ModalBody>
          {step === 1 && <HrWorkerCreateMainData workerDetails={workerDetails} />}
          {step === 2 && <HrAddressForm addressData={extractedFormValues as HrAddress} />}
          {step === 3 && <HrHealthcareAndFinanceForm data={extractedFormValues as HrHealthCareAndFinanceData} />}
          {step === 4 && <HrWorkerCreateFormSummaryStep />}
          {selectedWorker && <HrWorkerDataPreview hrWorker={selectedWorker} onModalHide={toggleIsVisible} />}
        </ModalBody>
      </ContentLoader>

      <ModalFooter style={{ display: 'block' }}>
        <Row>
          <Col md={6} className="p-0">
            {step !== 1 && (
              <Button onClick={handlePrevStep} className="btn btn-light">
                Wstecz
              </Button>
            )}
          </Col>
          <Col md={6} className="text-right p-0">
            {step === 4 ? (
              <SaveButton className="btn btn-green" isSaving={isLoading} labelSaving="Zapisywanie..." label="Zapisz" />
            ) : selectedWorker ? (
              <Button className="btn btn-green" onClick={toggleIsVisible} type="button">
                Zamknij
              </Button>
            ) : (
              <Button className="btn btn-green" onClick={handleNextStep} type="button" disabled={isNextStepDisabled()}>
                Następny krok
              </Button>
            )}
          </Col>
        </Row>
      </ModalFooter>
    </Form>
  )
}
