import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { SaveButton } from '@hyper/button'
import { useForm } from 'react-hook-form'
import { startOfToday } from 'date-fns'
import { useFormRequest } from '@components/hooks/use-api-request'
import { updateSubscriptionDetails } from '@store/slices/subscription-slice'
import { commonObjectPost } from '@store/actions/generic-actions'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { useAppDispatch } from '@store/index'
import { BaseModalProps } from '@components/modals/types'
import { PaymentCreateModalFormInputs } from '@modules/subscription/details/payment-create-modal'
import { Form } from '@hyper/forms/form'
import { asDecimal, createSelectOption, extractSelectOptionsValues, formatPrice } from '@helpers/utils'
import { FormSelect } from '@hyper/forms/form-select'
import { useSellersOptions } from '@components/hooks/use-grouped-sellers'
import { makeDefaultSelectOption } from '@components/custom-react-select'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props extends BaseModalProps {
  subscriptionDetails: SubscriptionDetails
  amounts?: string[]
  description: string
  title: string
  message: string
  url: string
  additionalData?: any
  isDisabled?: boolean
}

export const SubscriptionSingleModalBase: React.FC<Props> = ({
  description,
  title,
  subscriptionDetails,
  toggleIsVisible,
  url,
  amounts,
  message,
  additionalData,
  isDisabled,
}) => {
  const { addSuccessMessage } = useNotificationHook()
  const dispatch = useAppDispatch()
  const sellersOptions = useSellersOptions()

  const user = useAuthenticatedUser()

  const amountOptions = amounts?.map(amount => ({ value: amount, label: formatPrice(amount) }))

  const methods = useForm<PaymentCreateModalFormInputs>({
    defaultValues: {
      amount: amounts?.length ? createSelectOption(formatPrice(amounts[0]), amounts[0]) : undefined,
      invoice_date: startOfToday(),
      description,
      seller: makeDefaultSelectOption(sellersOptions, user?.seller?.id),
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: PaymentCreateModalFormInputs) => {
    const upgradeToPurple = subscriptionDetails.options.find(option => option.kind === 'upgrade_to_purple')
    if (upgradeToPurple && asDecimal(payload.amount.value).eq(1) && asDecimal(upgradeToPurple.price_brutto).eq(1)) {
      methods.setError('amount', {
        type: 'manual',
        message: 'Do pakietu można dodać tylko jedną opcję w kwocie 1,00 zł',
      })
      return
    }
    dispatch(
      updateSubscriptionDetails(
        await commonObjectPost<SubscriptionDetails>(url, { ...extractSelectOptionsValues(payload), ...additionalData }),
      ),
    )
    addSuccessMessage('Sukces', message)
    toggleIsVisible()
  }, methods.setError)

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <ModalHeader toggle={toggleIsVisible}>
        {title} <span className="text-semi-strong">{subscriptionDetails.number}</span>
      </ModalHeader>
      <ModalBody>
        {isDisabled ? (
          'Brak możliwości sprzedaży opcji'
        ) : (
          <>
            {!!amountOptions && amountOptions?.length >= 1 && (
              <FormSelect options={amountOptions} name="amount" label="Kwota" />
            )}
            <FormSelect options={sellersOptions} name="seller" label="Sprzedawca" />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={toggleIsVisible}>
          Zamknij
        </Button>
        <SaveButton disabled={isDisabled} role="submit" className="btn btn-green" label="Zapisz" isSaving={isLoading} />
      </ModalFooter>
    </Form>
  )
}
