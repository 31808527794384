import * as React from 'react'
import Table from '@components/table/table'
import { useModal } from '@components/modals/use-modal'
import { useHrAppData } from '@modules/hr/use-hr-app-data'
import { getById } from '@helpers/utils'
import { useAppData } from '@components/hooks/use-app-data'
import { HrWorker } from '@modules/hr/workers/models'
import { useLazyGetHrWorkerDetailsQuery } from '@api/agreements'
import { Spinner } from 'reactstrap'
import { HrWorkerStatus } from '@modules/hr/workers/worker-status'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'

interface Props {
  worker: HrWorker
}

export const HrWorkersListTableRow: React.FC<Props> = ({ worker }) => {
  const user = useAuthenticatedUser()
  const { resorts } = useAppData()
  const { companies, job_positions } = useHrAppData()

  const [showDetailsModal] = useModal('HrWorkerDetailsModal', { worker }, { persist: true })
  const [showCreationModal] = useModal('HrWorkerCreateModal', {
    onWorkerSuccessEdit: () => showDetailsModal(null, { worker, section: 'documents' }),
  })

  const [getHrWorkerDetails, { isLoading }] = useLazyGetHrWorkerDetailsQuery()

  const handleShowEditModal = async () => {
    const workerDetails = await getHrWorkerDetails(worker.urls.details).unwrap()
    showCreationModal(null, { workerDetails })
  }

  const company = worker.company ? getById(companies, worker.company)?.name : '-'
  const jobPosition = worker.job_position ? getById(job_positions, worker.job_position)?.name : '-'
  const workerResorts = worker.resorts.map(resort => getById(resorts, resort)?.name).join(', ')

  return (
    <Table.Row onDoubleClick={showDetailsModal} className="cursor-pointer" role="details-row">
      <Table.Cell>
        {worker.first_name} {worker.last_name}
      </Table.Cell>
      <Table.Cell>{worker.tax_id}</Table.Cell>
      <Table.Cell style={{ maxWidth: 200 }}>{workerResorts}</Table.Cell>
      <Table.Cell>{company}</Table.Cell>
      <Table.Cell>{jobPosition}</Table.Cell>
      <Table.Cell>
        <HrWorkerStatus worker={worker} />
      </Table.Cell>

      <Table.Cell className="text-right text-nowrap">
        {user.hasPerm(UserPermission.HrWorkerProfileCanAdd) && !worker.high_priority && (
          <span>
            {isLoading ? (
              <Spinner size="sm" />
            ) : (
              <i className="text-muted font-18 uil-edit-alt ml-2 cursor-pointer" onClick={handleShowEditModal} />
            )}
          </span>
        )}
        <i className="uil-eye font-16 ml-2 text-muted" onClick={showDetailsModal} />
      </Table.Cell>
    </Table.Row>
  )
}
