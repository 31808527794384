import * as React from 'react'
import { Row } from 'reactstrap'
import { ColAuto } from '@hyper/col-auto'
import { IconWithText } from '@components/icon-with-text'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'

interface Props {
  title: React.ReactNode
  adminUrl?: string
  isEditAllowed?: boolean
  icon?: string
  onEdit?: () => void
  editTitle?: string
  children?: React.ReactNode
}

export const ProductCardTitle = ({
  title,
  editTitle = 'zmień',
  adminUrl,
  onEdit,
  isEditAllowed = false,
  icon,
  children,
}: Props): JSX.Element => {
  const user = useAuthenticatedUser()

  return (
    <Row>
      <ColAuto>
        <h4 className="mt-0 text-secondary mb-3 text-semi-strong">
          {icon ? <IconWithText icon={icon} text={title} /> : title}
          {user.is_superuser && adminUrl && (
            <a className="h6 btn-pure text-primary ml-1" href={adminUrl}>
              (admin)
            </a>
          )}
        </h4>
      </ColAuto>
      {isEditAllowed && onEdit && (
        <ColAuto className="col-auto ml-auto">
          <IconWithText
            icon="uil-edit-alt font-16"
            text={editTitle}
            wrapperClassNames="cursor-pointer text-secondary"
            textClass="font-10 text-semi-strong"
            onClick={onEdit}
          />
        </ColAuto>
      )}
      {children}
    </Row>
  )
}
