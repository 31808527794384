import * as React from 'react'
import { HRJobPosition } from '@modules/hr/agreement/models'
import { formatPriceShort } from '@helpers/utils'
import { FormInput } from '@hyper/forms'
import { AgreementFormInputs } from '@modules/hr/agreement/create/modal'
import { useFormContext } from 'react-hook-form'

interface Props {
  position: HRJobPosition | undefined
  isDisabled?: boolean
  colClassName?: string
  colSize: number
}

export const AgreementHourlyRateInput = ({ position, isDisabled, colClassName, colSize }: Props): JSX.Element => {
  const { setValue } = useFormContext<AgreementFormInputs>()

  return (
    <FormInput
      labelProps={{ className: 'w-100' }}
      label={<HourlyRateLabel selectedPosition={position} />}
      name="hourly_rate"
      placeholder="Stawka godzinowa"
      formGroupClassName="mb-1"
      type="currency"
      setValue={setValue}
      colClassName={colClassName}
      disabled={isDisabled}
      colSize={colSize}
    />
  )
}

const HourlyRateLabel = ({ selectedPosition }: { selectedPosition: HRJobPosition | undefined }) => (
  <div className="d-flex justify-content-between w-100">
    <div className="text-nowrap">
      Stawka godzinowa <small>(brutto)</small>
    </div>
    <div className="font-10 ml-1 align-self-end fw-semi-bold text-nowrap">
      {selectedPosition ? (
        <span>
          od {formatPriceShort(selectedPosition.earnings_from)} do {formatPriceShort(selectedPosition.earnings_to)}
        </span>
      ) : (
        ''
      )}
    </div>
  </div>
)
