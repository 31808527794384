import * as React from 'react'
import { usePackageWholesaleAppData } from '@modules/package-wholesale/hooks/use-package-wholesale-app-data'
import { createSelectOption } from '@helpers/utils'
import { ProductOptions } from '@components/products/product-options'

export const PackageWholesaleProductCreateFormSubscriptionAdditionalOptions = (): JSX.Element => {
  const packageWholesaleAppData = usePackageWholesaleAppData()

  const availableOptions =
    packageWholesaleAppData.available_options?.map(option => createSelectOption(option.name, option.kind)) || []

  return (
    <div className="mb-3">
      <ProductOptions options={availableOptions} withDescription />
    </div>
  )
}
