import * as React from 'react'
import { FeedingClientsCreateDialogFormInputs } from '@modules/feeding/clients/create/index'
import { feedingClientCalculate, FeedingClientCalculateResponse } from '@api/feeding'
import { extractSelectOptionsValues, formatPriceShort } from '@helpers/utils'
import { TotalPriceRowGuestsFeedingClientsCreateDialogTotalPriceRow } from '@modules/feeding/clients/create/total-price-row'
import { useFormContext, useWatch } from 'react-hook-form'
import { useCancelablePromise } from '@components/hooks/use-cancelable-promise'
import { useDebounce } from 'rooks'
import { useApiRequest } from '@components/hooks/use-api-request'
import { PriceLoader } from '@modules/reception/checkin/step-feeding/price-loader'

export const GuestsFeedingClientsCreateDialogTotalPrice = () => {
  const [calculation, setCalculation] = React.useState<FeedingClientCalculateResponse>()
  const { control } = useFormContext<FeedingClientsCreateDialogFormInputs>()

  const formData = useWatch({ control })

  const { action: calculate, isLoading } = useApiRequest(
    async (cancelToken, data: FeedingClientsCreateDialogFormInputs) => {
      if (!data.kind || !data.resort || !data.payment_method) return

      setCalculation(await feedingClientCalculate(extractSelectOptionsValues(data), cancelToken))
    },
  )

  const debouncedCalculation = useDebounce(calculate, 500)

  useCancelablePromise(cancelToken => debouncedCalculation(cancelToken, formData), [formData])

  return (
    <div className="text-right font-16 mt-2">
      <div>
        {calculation && (
          <>
            <TotalPriceRowGuestsFeedingClientsCreateDialogTotalPriceRow
              value={calculation.guests.adult}
              label="Dorosły i młodzież"
            />
            <TotalPriceRowGuestsFeedingClientsCreateDialogTotalPriceRow
              value={calculation.guests.children_5_12}
              label="Dzieci od 5 do 12 lat"
            />
            <TotalPriceRowGuestsFeedingClientsCreateDialogTotalPriceRow
              value={calculation.guests.children_3_4}
              label="Dzieci od 3 do 5 lat"
            />
            <TotalPriceRowGuestsFeedingClientsCreateDialogTotalPriceRow
              value={calculation.guests.baby}
              label="Dzieci do 3 lat"
            />
          </>
        )}

        {calculation && (
          <div className="font-20">
            <span className="text-semi-strong">Do zapłaty:</span>{' '}
            <PriceLoader
              content={<strong>{formatPriceShort(calculation.total_brutto)}</strong>}
              price={calculation.total_brutto}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    </div>
  )
}
