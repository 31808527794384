import * as React from 'react'
import { DropdownItem } from 'reactstrap'
import {
  SubscriptionDetails,
  SubscriptionProductOption,
  SubscriptionProductOptionKindKey,
} from '@modules/subscription/models'
import { SubscriptionTypeStrategyOption } from '@store/slices/subscription-slice'
import { useModal } from '@components/modals/use-modal'
import { SubscriptionDetailsCodeConversions } from '@modules/subscription/details/options/code-conversion'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'

interface Option extends Omit<SubscriptionProductOption, 'kind' | 'price_brutto'> {
  kind: SubscriptionTypeStrategyOption
  handler: any
  prices: string[]
}

interface Props {
  subscriptionDetails: SubscriptionDetails
  getOptions: (
    params: Partial<Record<SubscriptionTypeStrategyOption, boolean>>,
  ) => Record<SubscriptionTypeStrategyOption, boolean>
}

export const SubscriptionDetailsInformationProductOptions = ({
  subscriptionDetails,
  getOptions,
}: Props): React.ReactNode => {
  const { allow_options } = useSubscriptionTypeStrategyProvider(subscriptionDetails.type)

  const [handleUpgradeToPurple] = useModal('SubscriptionUpgradeToPurpleModal', {}, { persist: true })
  const [handleEntranceTicket] = useModal('SubscriptionEntranceTickets', {}, { persist: true })

  const [handleSingleChangeData] = useModal('SubscriptionSingleChangeDataModal', {}, { persist: true })
  const [handleChangeData] = useModal('SubscriptionChangeDataModal', {}, { persist: true })

  const [handleSingleChangeDate] = useModal('SubscriptionSingleChangeDateModal', {}, { persist: true })

  const [handleChangeLocalization] = useModal('SubscriptionChangeLocalizationModal', {}, { persist: true })
  const [handleSingleChangeLocalization] = useModal('SubscriptionSingleChangeLocalizationModal', {}, { persist: true })

  const OptionsMap: Record<SubscriptionProductOptionKindKey, { kind: SubscriptionTypeStrategyOption; handler: any }> =
    React.useMemo(
      () => ({
        client_data: { kind: 'change_data', handler: handleChangeData },
        single_client_data: { kind: 'single_change_data', handler: handleSingleChangeData },
        localization_unlimited: { kind: 'localization', handler: handleChangeLocalization },
        localization: { kind: 'single_localization', handler: handleSingleChangeLocalization },
        single_client_date: { kind: 'single_change_date', handler: handleSingleChangeDate },
        upgrade_to_purple: { kind: 'upgrade_to_purple', handler: handleUpgradeToPurple },
        zoo_borysew: { kind: 'zoo_borysew', handler: handleEntranceTicket },
        suntago: { kind: 'suntago', handler: handleEntranceTicket },
      }),
      [],
    )

  if (!subscriptionDetails.product?.options.length) return null

  const options = subscriptionDetails.product.options.reduce((prev, option) => {
    const mappedData = OptionsMap[option.kind]
    const availableOptions = getOptions({ [mappedData.kind]: true })

    const isAlreadyAdded = prev.some((el: Option) => el.kind === mappedData.kind)

    if (isAlreadyAdded) {
      return prev.map((el: Option) =>
        el.kind === mappedData.kind ? { ...el, ...mappedData, prices: [...el.prices, option.price_brutto] } : el,
      )
    }

    return availableOptions[mappedData.kind]
      ? [...prev, { ...option, ...mappedData, prices: [option.price_brutto] }]
      : prev
  }, []) as Option[]

  const getOptionParams = (option: Option) => {
    if (
      ['upgrade_to_purple', 'localization', 'single_localization', 'single_change_date', 'single_change_data'].includes(
        option.kind,
      )
    ) {
      return { prices: option.prices }
    }
    if (option.kind === 'zoo_borysew') return { ticketKind: 'zoo_borysew', prices: option.prices }
    if (option.kind === 'suntago') return { ticketKind: 'suntago', prices: option.prices }
    return {}
  }

  const handleClick = (option: Option) => () => {
    option.handler(null, { subscriptionDetails, ...getOptionParams(option) })
  }

  const hasCodeKindConvertOptions = getOptions({
    code_kind_convert: allow_options.includes('code_kind_convert'),
  }).code_kind_convert

  return (
    <>
      {options.map(option => (
        <DropdownItem key={option.kind} onClick={handleClick(option)}>
          {option.kind_display}
        </DropdownItem>
      ))}
      {hasCodeKindConvertOptions && <SubscriptionDetailsCodeConversions subscriptionDetails={subscriptionDetails} />}
    </>
  )
}
