import * as React from 'react'
import { DropdownItem } from 'reactstrap'
import { SubscriptionDetailsCodeConversions } from '@modules/subscription/details/options/code-conversion'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { SubscriptionDetails } from '@modules/subscription/models'
import { useModal } from '@components/modals/use-modal'
import { SubscriptionTypeStrategyOption } from '@store/slices/subscription-slice'
import { useAppData } from '@components/hooks/use-app-data'

interface Props {
  subscriptionDetails: SubscriptionDetails
  getOptions: (
    params: Partial<Record<SubscriptionTypeStrategyOption, boolean>>,
  ) => Record<SubscriptionTypeStrategyOption, boolean>
}

export const SubscriptionDetailsInformationStrategyOptions = ({
  subscriptionDetails,
  getOptions,
}: Props): React.ReactNode => {
  const { settings } = useAppData()
  const { allow_options } = useSubscriptionTypeStrategyProvider(subscriptionDetails.type)

  const [handleUpgradeToPurple] = useModal('SubscriptionUpgradeToPurpleModal', {}, { persist: true })
  const [handleSingleChangeData] = useModal('SubscriptionSingleChangeDataModal', {}, { persist: true })
  const [handleSingleChangeDate] = useModal('SubscriptionSingleChangeDateModal', {}, { persist: true })
  const [handleEntranceTicket] = useModal('SubscriptionEntranceTickets', {}, { persist: true })
  const [handleChangeData] = useModal('SubscriptionChangeDataModal', {}, { persist: true })
  const [handleChangeLocalization] = useModal('SubscriptionChangeLocalizationModal', {}, { persist: true })
  const [handleSingleChangeLocalization] = useModal('SubscriptionSingleChangeLocalizationModal', {}, { persist: true })
  const [handleExpirationExtend] = useModal('SubscriptionExpirationExtendModal', {}, { persist: true })

  const handleClick =
    (handler: any, params = {}) =>
    () => {
      handler(null, { subscriptionDetails, ...params })
    }

  const options = getOptions({
    change_data: allow_options.includes('change_data'),
    single_change_data: allow_options.includes('single_change_data'),
    localization: allow_options.includes('localization'),
    single_localization: allow_options.includes('localization'),
    code_kind_convert: allow_options.includes('code_kind_convert'),
    single_change_date: allow_options.includes('single_change_date'),
    upgrade_to_purple: allow_options.includes('upgrade_to_purple'),
    zoo_borysew: allow_options.includes('zoo_borysew'),
    suntago: allow_options.includes('suntago'),
    expiration_extend: allow_options.includes('expiration_extend'),
  })

  return (
    <div>
      {options.change_data && (
        <DropdownItem onClick={handleClick(handleChangeData)}>Zmiana danych (wielokrotna)</DropdownItem>
      )}

      {options.single_change_data && (
        <DropdownItem
          onClick={handleClick(handleSingleChangeData, {
            prices: [subscriptionDetails.product?.subscription_disposable_client_data_change_price || ''],
          })}
        >
          Zmiana danych (jednorazowa)
        </DropdownItem>
      )}

      {options.localization && (
        <DropdownItem onClick={handleClick(handleChangeLocalization)}>Zmiana lokalizacji (wielokrotna)</DropdownItem>
      )}

      {options.single_localization && (
        <DropdownItem onClick={handleClick(handleSingleChangeLocalization)}>
          Zmiana lokalizacji (jednorazowa)
        </DropdownItem>
      )}

      {options.code_kind_convert && <SubscriptionDetailsCodeConversions subscriptionDetails={subscriptionDetails} />}

      {options.single_change_date && (
        <DropdownItem
          onClick={handleClick(handleSingleChangeDate, {
            prices: [subscriptionDetails.product?.subscription_disposable_booking_date_change_price ?? ''],
          })}
        >
          Zmiana daty pobytu (jednorazowa)
        </DropdownItem>
      )}

      {options.upgrade_to_purple && (
        <DropdownItem
          onClick={handleClick(handleUpgradeToPurple, { prices: settings.subscription_upgrade_to_purple_prices })}
        >
          Dostęp do lokali specjalnych
        </DropdownItem>
      )}

      {options.zoo_borysew && (
        <DropdownItem
          onClick={handleClick(handleEntranceTicket, {
            ticketKind: 'zoo_borysew',
            prices: [subscriptionDetails.product?.zoo_borysew_tickets_single_price_brutto ?? ''],
          })}
        >
          Bilety - Zoo Borysew (4szt.)
        </DropdownItem>
      )}

      {options.suntago && (
        <DropdownItem
          onClick={handleClick(handleEntranceTicket, {
            ticketKind: 'suntago',
            prices: [subscriptionDetails.product?.suntago_tickets_single_price_brutto ?? ''],
          })}
        >
          Bilety - Suntago (4szt.)
        </DropdownItem>
      )}

      {options.expiration_extend && (
        <DropdownItem onClick={handleClick(handleExpirationExtend)}>
          Przedłużenie ważności kodu (jednorazowe)
        </DropdownItem>
      )}
    </div>
  )
}
