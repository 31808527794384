import * as React from 'react'
import { Row } from 'reactstrap'
import { FormSelect } from '@hyper/forms/form-select'
import { FormInput } from '@hyper/forms'
import { CustomReactSelectOption } from '@components/custom-react-select'

interface Props {
  isRemovable: boolean
  onDelete: (index: number) => void
  index: number
  availableOptions: CustomReactSelectOption[]
  withDescription?: boolean
}

export const ProductOptionsRow = ({
  isRemovable,
  onDelete,
  index,
  availableOptions,
  withDescription,
}: Props): React.ReactNode => (
  <Row>
    <FormSelect
      options={availableOptions}
      name={`options.${index}.kind`}
      label="Opcja dodatkowa"
      formPlainProps={{ colSize: withDescription ? 4 : 8 }}
      selectProps={{ placeholder: 'Wybierz rodzaj opcji', isSearchable: false }}
    />
    <FormInput
      label="Cena"
      name={`options.${index}.price_brutto`}
      type="currency"
      placeholder="120.00 zł"
      colSize={withDescription ? 2 : 3}
    />
    {withDescription && (
      <FormInput
        label="Opis"
        inputProps={{ rows: 1 }}
        colSize={isRemovable ? 5 : 6}
        placeholder="Opis opcji"
        formGroupClassName="mb-0"
        type="textarea"
        name={`options.${index}.description`}
      />
    )}
    {isRemovable && (
      <i
        style={{ marginTop: '30px' }}
        className="uil-trash-alt font-18 pb-1 opacity-75 cursor-pointer col-1"
        onClick={() => onDelete(index)}
      />
    )}
  </Row>
)
