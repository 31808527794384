import { UncontrolledTooltip } from 'reactstrap'
import * as React from 'react'

export const withTooltip =
  (additionalProps = {}) =>
  Component =>
  ({ tooltipMessage, tooltipId, tooltipPlacement, tooltipDisabled = false, ...props }) => (
    <>
      {!tooltipDisabled && (
        <UncontrolledTooltip
          placement={tooltipPlacement || 'bottom'}
          target={tooltipId}
          role="tooltip"
          modifiers={{ offset: { enabled: true, offset: '0, 5px' } }}
        >
          {tooltipMessage}
        </UncontrolledTooltip>
      )}

      <div id={tooltipId} className="d-inline">
        <Component {...props} {...additionalProps} />
      </div>
    </>
  )
