import * as React from 'react'
import { WorkerAgreementCard } from '@modules/hr/workers/details/agreements/worker-agreement-card'
import { Agreement } from '@modules/hr/agreement/models'
import { sortObjectListByDate } from '@helpers/utils'
import { compareDesc } from 'date-fns'

interface Props {
  agreements: Agreement[]
}

export const WorkerAgreementCards = ({ agreements }: Props): React.ReactNode => {
  const { activeAgreements, restAgreements } = agreements.reduce(
    (prev, agreement) =>
      agreement.status === 'active'
        ? { ...prev, activeAgreements: [...prev.activeAgreements, agreement] }
        : { ...prev, restAgreements: [...prev.restAgreements, agreement] },
    { activeAgreements: [], restAgreements: [] },
  )

  const sortedRestAgreements = sortObjectListByDate(restAgreements, 'date_of_start', compareDesc)

  return (
    <div>
      {activeAgreements.map((agreement: Agreement) => (
        <WorkerAgreementCard key={agreement.id} agreement={agreement} isActive />
      ))}
      {sortedRestAgreements.map((agreement: Agreement, index: number) => (
        <OldAgreementSection
          key={agreement.id}
          agreement={agreement}
          startWithSeparator={!!activeAgreements.length || index > 0}
        />
      ))}
    </div>
  )
}

interface SectionProps {
  agreement: Agreement
  startWithSeparator: boolean
}

const OldAgreementSection = ({ agreement, startWithSeparator }: SectionProps) => (
  <div className="mt-2">
    {startWithSeparator && <div className="col-6 mb-2" style={{ height: 20, borderRight: '1px dashed #ADB5BD' }} />}
    <WorkerAgreementCard agreement={agreement} />
  </div>
)
