import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Col, CustomInput } from 'reactstrap'
import { FormInput, FormInputProps, FormPlain } from '@hyper/forms'
import { extractInnerRef } from '@helpers/forms'
import { CustomInputProps } from 'reactstrap/es/CustomInput'

interface Props {
  optionFieldName: string
  optionLabel: React.ReactNode
  amountInputProps: FormInputProps
  checkboxProps?: Partial<CustomInputProps>
  children?: React.ReactNode
  colSize?: number
}

export const OptionWithAmount = ({
  optionFieldName,
  optionLabel,
  amountInputProps,
  checkboxProps,
  children,
  colSize,
}: Props): React.ReactNode => {
  const { register, control } = useFormContext()
  const isOptionChecked = useWatch({ control, name: optionFieldName })

  return (
    <Col md={colSize} className="d-flex align-items-center mb-2">
      <FormPlain name={optionFieldName} colClassName="px-0" colSize={7} formGroupClassName="mb-0">
        <CustomInput
          type="checkbox"
          id={optionFieldName}
          label={optionLabel}
          {...extractInnerRef(register(optionFieldName))}
          {...checkboxProps}
        />
      </FormPlain>
      <div className="col-5 d-flex align-items-center">
        <FormInput
          colClassName="pl-0"
          type="number"
          {...amountInputProps}
          colSize="col-auto"
          formGroupClassName="mb-0"
          inputProps={{
            ...amountInputProps.inputProps,
            disabled: amountInputProps.disabled || !isOptionChecked,
            style: { maxWidth: 100, width: 100 },
          }}
        />
        <div className="ml-2">{children}</div>
      </div>
    </Col>
  )
}
