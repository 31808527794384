import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { formatPrice } from '@helpers/utils'
import { CustomInput, Row } from 'reactstrap'
import { extractInnerRef } from '@helpers/forms'
import { useFormContext, useWatch } from 'react-hook-form'
import { PackageWholesaleProductFormInputs } from '@modules/package-wholesale/products/create/modal'
import { bruttoToNetto } from '@helpers/price'
import { FormSelect } from '@hyper/forms/form-select'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { PackageWholesaleProductCreateFormSubscriptionAdditionalOptions } from '@modules/package-wholesale/products/create/additional-options'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'
import { IconWithTooltip } from '@components/icon-with-tooltip'
import { useAppData } from '@components/hooks/use-app-data'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'

interface Props {
  accommodationTypeOptions: CustomReactSelectOption[]
  depositAmountOptions: CustomReactSelectOption[]
  localizationKindOptions: CustomReactSelectOption[]
  defaultsDaysOptions: CustomReactSelectOption[]
}

export const PackageWholesaleProductCreateFormSubscription = ({
  accommodationTypeOptions,
  localizationKindOptions,
  depositAmountOptions,
  defaultsDaysOptions,
}: Props): JSX.Element => {
  const { register, control, watch } = useFormContext<PackageWholesaleProductFormInputs>()
  const { deposit_payment_deadline } = useAppData()
  const { subscription_code_name_kinds } = useSubscriptionAppData()

  const { subscriptionKindOptions, getSubscriptionKindVersionOptions } = useSubscriptionKinds()

  const priceBrutto = watch('price_brutto')
  const priceNetto = React.useMemo(() => (priceBrutto ? bruttoToNetto(priceBrutto, 8) : ''), [priceBrutto])

  const subscription_kind = useWatch({ control, name: 'subscription_kind' })
  const subscriptionKindVersionOptions = getSubscriptionKindVersionOptions(subscription_kind)

  return (
    <>
      <FormSelect
        options={subscriptionKindOptions}
        label="Rodzaj pakietu"
        name="subscription_kind"
        formPlainProps={{ colSize: 4 }}
      />
      {!!subscriptionKindVersionOptions.length && (
        <FormSelect
          name="subscription_kind_version"
          label="Wersja rodzaju"
          formPlainProps={{ colSize: 4 }}
          options={subscriptionKindVersionOptions}
        />
      )}
      <FormInput label="Prefiks kodów" name="prefix" colSize={4} />

      <FormSelect
        formPlainProps={{ colSize: 4 }}
        options={accommodationTypeOptions}
        name="accommodation_type"
        label="Zakwaterowanie"
      />
      <FormSelect
        formPlainProps={{ colSize: 4 }}
        options={localizationKindOptions}
        name="localization_kind"
        label="Lokalizacja"
      />
      <FormSelect
        formPlainProps={{ colSize: 4 }}
        options={depositAmountOptions}
        name="deposit_amount"
        label="Kwota kaucji"
      />
      <FormSelect
        formPlainProps={{ colSize: 4 }}
        options={defaultsDaysOptions}
        name="defaults_days"
        label="Długość pobytu (doby)"
      />
      <FormInput
        colSize={4}
        type="number"
        labelProps={{ className: 'col-12 pl-0' }}
        name="discount"
        formGroupClassName="d-flex flex-wrap align-items-center"
        inputClassName="col-8"
        inputProps={{ min: 0, max: 100 }}
        afterInput={<span className="col-4">%</span>}
        label={
          <>
            <span>Rabat</span>
            <IconWithTooltip
              icon="uil-exclamation-circle ml-1"
              tooltipId="tooltip-id"
              tooltipPlacement="top"
              tooltipMessage={
                <span className="font-11 text-left">
                  <span>Rabat informacyjny.</span> <br />
                  Wyświetlany w produktach na stronie sprzedażowej.
                </span>
              }
              color="text-secondary"
            />
          </>
        }
      />
      <FormInput label="Rodzaj kodów" name="subscription_code_name_kind" colSize={4} type="select">
        {subscription_code_name_kinds.map(([value, label]) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </FormInput>
      <FormInput label="Termin wpłaty kaucji" name="deposit_payment_deadline" colSize={4} type="select">
        {deposit_payment_deadline.map(([value, label]) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
        <option value="">Domyślne działanie</option>
      </FormInput>
      <Row className="my-2 mx-0">
        <FormPlain colSize={6} name="with_cleaning_option">
          <CustomInput
            className="cursor-pointer"
            type="checkbox"
            {...extractInnerRef(register('with_cleaning_option'))}
            id="with_cleaning_option"
            label="Sprzątanie w cenie"
          />
        </FormPlain>
        <FormPlain colSize={6} name="with_parking">
          <CustomInput
            className="cursor-pointer"
            type="checkbox"
            {...extractInnerRef(register('with_parking'))}
            id="with_parking"
            label="Parking w cenie"
          />
        </FormPlain>
        <FormPlain colSize={6} name="with_parking">
          <CustomInput
            className="cursor-pointer"
            type="checkbox"
            {...extractInnerRef(register('with_towel'))}
            id="with_towel"
            label="1 Komplet ręczników w cenie"
          />
        </FormPlain>
        <FormPlain colSize={6} name="with_grill">
          <CustomInput
            className="cursor-pointer"
            type="checkbox"
            {...extractInnerRef(register('with_grill'))}
            id="with_grill"
            label="Grill w cenie"
          />
        </FormPlain>
        <FormPlain colSize={6} name="with_client_change_data_option">
          <CustomInput
            className="cursor-pointer"
            type="checkbox"
            {...extractInnerRef(register('with_client_change_data_option'))}
            id="with_client_change_data_option"
            label="Jednorazowa opcja zmiany danych w cenie"
          />
        </FormPlain>
      </Row>
      <PackageWholesaleProductCreateFormSubscriptionAdditionalOptions />
      <div className="d-flex w-100">
        <div className="col-6 d-flex">
          <FormInput label="Cena brutto" name="price_brutto" colSize={8} colClassName="pl-0" type="currency" />
          <div className="col-4 pl-0">
            <p>Kwota netto:</p>
            {priceNetto ? formatPrice(priceNetto) : '-'}
          </div>
        </div>

        <FormInput label="Cena standardowa brutto" name="standard_price_brutto" colSize={6} type="currency" />
      </div>
    </>
  )
}
