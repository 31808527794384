import * as React from 'react'
import { useFormRequest } from '@components/hooks/use-api-request'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { PackageDetails } from '@models/package'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '@store/index'
import { CardBody, Col, Label, Row } from 'reactstrap'
import * as R from 'ramda'
import { SaveButton } from '@hyper/button'
import { FormInput, FormPlain, FormPlainError } from '@hyper/forms'
import { useGroupedSellers, useSellersOptions } from '@components/hooks/use-grouped-sellers'
import DatePickerInput from '@components/date/date-picker-input'
import { CustomReactSelect, CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { createFormData } from '@helpers/forms'
import { updatePackageDetails } from '@store/slices/package-slice'
import { parseISODate } from '@helpers/date-helper'
import { Form } from '@hyper/forms/form'
import { FileFormElementRow } from '@modules/promotions/common/file-form-element-row'
import { createSelectOption, extractSelectOptionsValues, formatPriceShort } from '@helpers/utils'
import { DepositPaymentDeadline } from '@models/dashboard'
import { useAppData } from '@components/hooks/use-app-data'
import { usePackageAccommodationCodes } from '@modules/package/create/steps/params/use-package-accommodation-codes'

interface Props {
  packageDetails: PackageDetails
  toggleEdit: () => void
}

interface FormInputs {
  deposit_payment_deadline: DepositPaymentDeadline
  deposit_amount?: CustomReactSelectOption
  deposit_house_amount?: CustomReactSelectOption
  seller: CustomReactSelectOption
  expire_after: string
  email_notifications: string
  instruction_url: string
}

export const PackageDetailsBaseInformationForm: React.FC<Props> = ({ packageDetails, toggleEdit }) => {
  const { deposit_payment_deadline } = useAppData()

  const sellers = useGroupedSellers([])
  const depositAmounts = useAppSelector(state => state.packageState.appData.deposit_amount)
  const { hasHouseCodes } = usePackageAccommodationCodes(packageDetails)
  const depositAmountOptions = React.useMemo(
    () => depositAmounts.map(amount => createSelectOption(formatPriceShort(amount), amount)),
    depositAmounts,
  )

  const methods = useForm<FormInputs>({
    defaultValues: {
      ...R.pick<FormInputs, any>(
        ['deposit_payment_deadline', 'deposit_amount', 'deposit_house_amount', 'seller', 'expire_after'],
        {
          ...packageDetails,
          expire_after: parseISODate(packageDetails.expire_after),
          deposit_amount: makeDefaultSelectOption(depositAmountOptions, packageDetails.deposit_amount),
          deposit_house_amount: makeDefaultSelectOption(depositAmountOptions, packageDetails.deposit_house_amount),
          seller: makeDefaultSelectOption(useSellersOptions(), packageDetails.seller_id),
        },
      ),
      instruction_url: packageDetails.instruction,
    },
  })

  const dispatch = useAppDispatch()
  const { addSuccessNotification } = useNotificationHook()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    dispatch(
      updatePackageDetails(
        await commonObjectPatch<PackageDetails>(
          packageDetails.urls.details,
          createFormData(extractSelectOptionsValues(payload)),
        ),
      ),
    )
    addSuccessNotification('Zmiany zostały zapisane')
    toggleEdit()
  }, methods.setError)

  return (
    <CardBody>
      <h4 className="mt-0 text-secondary mb-3">Szczegóły pakietu</h4>
      <Form methods={methods} className="form" onSubmit={onSubmit}>
        <FormRow label="Kwota kaucji apartament">
          <CustomReactSelect fieldName="deposit_amount" options={depositAmountOptions} />
        </FormRow>

        <FormRow label="Kwota kaucji domek">
          <CustomReactSelect
            fieldName="deposit_house_amount"
            options={depositAmountOptions}
            isDisabled={!hasHouseCodes}
          />
        </FormRow>

        <FormRow label="Sprzedawca">
          <CustomReactSelect fieldName="seller" options={sellers} />
        </FormRow>

        <FormRow label="Data ważności">
          <FormPlain name="expire_after" colClassName="px-0">
            <DatePickerInput name="expire_after" wrapperClassName="flex-grow-1" />
            <FormPlainError name="expire_after" />
          </FormPlain>
        </FormRow>

        <Row>
          <FileFormElementRow
            inputName="instruction"
            label="Instrukcja korzystania z vouchera"
            labelClassName="col-6"
            contentClassName="col-6 pr-0"
          />
        </Row>

        <FormInput
          labelProps={{ className: 'col-6 px-0' }}
          label={<span className="text-nowrap">Termin wpłaty kaucji</span>}
          name="deposit_payment_deadline"
          colSize={12}
          type="select"
          colClassName="px-0"
          formGroupClassName="d-flex"
        >
          {deposit_payment_deadline.map(([value, label]) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
          <option value="">Domyślne działanie</option>
        </FormInput>
        <Row>
          <Col md={12} className="text-right px-0">
            <button type="button" className="btn btn-light mr-1" onClick={toggleEdit}>
              Anuluj
            </button>
            <SaveButton isSaving={isLoading} label="Zapisz dane" className="btn btn-green" />
          </Col>
        </Row>
      </Form>
    </CardBody>
  )
}

const FormRow = ({ label, children }) => (
  <Row className="align-items-center mb-2">
    <Col md={6}>
      <Label className="mb-0">{label}</Label>
    </Col>
    <Col md={6} className="px-0">
      {children}
    </Col>
  </Row>
)
