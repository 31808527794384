import { BaseModalProps } from '@components/modals/types'
import * as React from 'react'
import { Button, Col, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { LoaderPlaceholder } from '@components/loader-placeholder'
import { HrWorker, HrWorkerDetails } from '@modules/hr/workers/models'
import { useGetHrWorkerDetailsQuery } from '@api/agreements'
import { HrFinanceDetails } from '@modules/hr/common/details/finance-details-information'
import { HrAddressDetails } from '@modules/hr/common/details/address-details-information'
import { TypedQueryResult } from '@api/base'
import { HistoryBox } from '@modules/promotions/common/history-box'
import { ContentNotesCard } from '@components/content-notes/content-notes-card'
import { HrBaseDetails } from '@modules/hr/common/details/base-details-information'
import { HrPositionDetails } from '@modules/hr/common/details/position-details-information'
import { HrWorkerDetailsAgreements } from '@modules/hr/workers/details/agreements/worker-agreements'
import { HrWorkerDetailsTabs } from '@modules/hr/workers/details/tabs'
import { useModal } from '@components/modals/use-modal'
import { useAppData } from '@components/hooks/use-app-data'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { HrWorkerProfileActions } from '@modules/hr/workers/details/worker-profile-actions'
import { SubscriptionDetailsInformationRow } from '@modules/subscription/details/information/row'
import { BadgeWithIcon } from '@components/badges/BadgeWithIcon'
import { HrWorkerDetailsDocuments } from '@modules/hr/workers/details/documents/worker-documents'
import { DocumentList } from '@components/documents/document-list'

type Section = 'documents'

interface Props extends BaseModalProps {
  worker: HrWorker
  section?: Section
}

export const HrWorkerDetailsModal: React.FC<Props> = ({ toggleIsVisible, section, worker }) => {
  const user = useAuthenticatedUser()
  const { hrworkerprofile: contentType } = useAppData().content_types

  const dataRef = React.useRef<HTMLElement>(null)
  const financeRef = React.useRef<HTMLElement>(null)
  const historyRef = React.useRef<HTMLElement>(null)
  const addressRef = React.useRef<HTMLElement>(null)
  const positionRef = React.useRef<HTMLElement>(null)
  const agreementsRef = React.useRef<HTMLElement>(null)
  const documentsRef = React.useRef<HTMLElement>(null)

  const {
    data: workerDetails,
    isLoading,
    refetch,
  } = useGetHrWorkerDetailsQuery(worker.urls.details) as TypedQueryResult<HrWorkerDetails>

  const positionData = workerDetails
    ? workerDetails.agreements.find(agreement => agreement.id === workerDetails.last_agreement_id)
    : undefined

  const activeAgreement = workerDetails?.agreements.find(agreement => agreement.status === 'active')

  const everyDocumentIsProcessed = !!workerDetails?.hr_documents.every(document => document.status === 'active')

  const scrollToDocuments = () => {
    if (documentsRef.current) {
      documentsRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const [showHrWorkerModal] = useModal('HrWorkerCreateModal', { workerDetails, onWorkerSuccessEdit: scrollToDocuments })

  React.useEffect(() => {
    if (section === 'documents') scrollToDocuments()
  }, [])

  return (
    <>
      <ModalHeader toggle={toggleIsVisible}>
        {worker.first_name} {worker.last_name} - informacje o pracowniku
      </ModalHeader>
      <ModalBody className="modal-details">
        <LoaderPlaceholder content={!isLoading}>
          {workerDetails && (
            <div className="modal-details__tabs-wrapper">
              <HrWorkerDetailsTabs
                dataRef={dataRef}
                financeRef={financeRef}
                addressRef={addressRef}
                positionRef={positionRef}
                agreementsRef={agreementsRef}
              />
              <Row>
                <Col md={6}>
                  <HrBaseDetails
                    editTitle="Aktualizacja danych"
                    isReadonly={
                      !user.hasPerm(UserPermission.HrWorkerProfileCanAdd) ||
                      !workerDetails.is_active ||
                      !everyDocumentIsProcessed
                    }
                    data={workerDetails}
                    ref={dataRef}
                    onEdit={showHrWorkerModal}
                  >
                    {!worker.is_active && (
                      <SubscriptionDetailsInformationRow name="Status pracownika">
                        <BadgeWithIcon icon="uil-user-times" variant="danger" title="Nieaktywny" />
                      </SubscriptionDetailsInformationRow>
                    )}
                  </HrBaseDetails>
                  <HrAddressDetails
                    isReadonly={true}
                    addressData={workerDetails}
                    ref={addressRef}
                    prefix=""
                    title="Adres zamieszkania"
                  />

                  {workerDetails.different_address && (
                    <HrAddressDetails
                      prefix="registered_"
                      isReadonly={true}
                      addressData={workerDetails}
                      ref={addressRef}
                      title="Adres zameldowania"
                    />
                  )}
                </Col>
                <Col md={6}>
                  <HrPositionDetails
                    title="Dane aktualnego stanowiska"
                    agreementId={activeAgreement?.id ?? ''}
                    isReadonly={true}
                    positionData={positionData}
                    ref={positionRef}
                    annexedFields={activeAgreement?.annexed_fields}
                  />
                  <HrFinanceDetails financeData={workerDetails} ref={financeRef} isReadonly={true} />
                </Col>
                <Col md={12}>
                  <HrWorkerDetailsDocuments workerDetails={workerDetails} ref={documentsRef} />
                </Col>
                <Col md={12}>
                  <HrWorkerDetailsAgreements workerDetails={workerDetails} ref={agreementsRef} />
                </Col>
                <Col md={12}>
                  <DocumentList
                    title="Załączniki"
                    addButtonText="Dodaj załączniki"
                    documents={workerDetails.attachments}
                    handleOnSave={refetch}
                    url={workerDetails.urls.documents}
                    maxFiles={20}
                  />
                </Col>
                <Col md={6}>
                  <HistoryBox history={workerDetails.history} ref={historyRef} />
                </Col>
                <Col md={6}>
                  <ContentNotesCard
                    readOnly={false}
                    objectId={workerDetails.id}
                    contentType={contentType}
                    onNotesUpdate={refetch}
                    notes={workerDetails.notes}
                  />
                </Col>
              </Row>
            </div>
          )}
        </LoaderPlaceholder>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        {workerDetails && <HrWorkerProfileActions worker={workerDetails} onWorkerRemove={toggleIsVisible} />}
        <div className="ml-auto">
          <Button className="btn btn-light mr-2" type="button" onClick={toggleIsVisible}>
            Wróć do listy Pracowników
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}
