import { PackageDetails, PackageType } from '@models/package'
import { commonObjectPost } from '@store/actions/generic-actions'
import store, { getState } from '@store/index'
import { formatDate } from '@helpers/date-helper'
import { PackageCreateFormInputs } from '@modules/package/create/modal'
import { extractSelectOptionsValues } from '@helpers/utils'
import { createFormData, extractUndefinedPropertiesFromObject } from '@helpers/forms'
import { nettoToBrutto } from '@helpers/price'
import { ParamsToCalculate } from '@modules/package/create/steps/params/params'
import { PackageAppDataPrices } from '@store/slices/package-slice'

export const packageCreate = async (
  payload: PackageCreateFormInputs,
  packageType: PackageType,
  prices: PackageAppDataPrices,
): Promise<PackageDetails> => {
  const url = store.getState().appState.appData.urls.package.packages

  const { zoo_borysew_tickets_single_price_net, deposit_house_amount, ...rest } = payload

  return await commonObjectPost<PackageDetails>(
    `${url}?package_type=${packageType}`,
    createFormData(
      extractUndefinedPropertiesFromObject({
        ...(extractSelectOptionsValues(rest) as object),
        package_type: packageType,
        expire_after: formatDate(rest.expire_after),
        ...(deposit_house_amount?.value !== null && { deposit_house_amount: deposit_house_amount?.value }),
        zoo_borysew_tickets_single_price_brutto: nettoToBrutto(
          zoo_borysew_tickets_single_price_net,
          prices.package_zoo_borysew_tickets_tax_rate,
        ),
      }),
    ),
  )
}

interface CalculationResponse {
  price_netto: string
  price_brutto: string
}

export const calculatePackagePrice = async (
  payload: ParamsToCalculate,
  prices: PackageAppDataPrices,
): Promise<CalculationResponse> => {
  const url = getState().appState.appData.urls.package.calculate
  const { zoo_borysew_tickets_single_price_net, suntago_tickets_single_price_net, ...rest } = payload

  return await commonObjectPost<CalculationResponse>(url, {
    ...extractSelectOptionsValues(rest),
    package_type: rest.package_type.toUpperCase(),
    zoo_borysew_tickets_single_price_brutto: nettoToBrutto(
      zoo_borysew_tickets_single_price_net,
      prices.package_suntago_tickets_tax_rate,
    ),

    suntago_tickets_single_price_brutto: nettoToBrutto(
      suntago_tickets_single_price_net ?? '0',
      prices.package_suntago_tickets_tax_rate,
    ),
  })
}
