import * as React from 'react'
import { Col, Row } from 'reactstrap'
import classNames from 'classnames'
import { AgreementStatusBadge } from '@modules/hr/common/details/status-badge'

interface SectionStyles {
  row?: React.CSSProperties
  status?: React.CSSProperties
  number?: React.CSSProperties
  type?: React.CSSProperties
  detailsIcon?: React.CSSProperties
}

interface Props {
  isSubsection?: boolean
  isFirstChild?: boolean
  sectionStyles?: SectionStyles
  children: React.ReactNode
  onShowDetailsClick: () => void
  status: string
  statusDisplay: string
}

export const BaseAnnexCard = ({
  isSubsection,
  isFirstChild,
  sectionStyles,
  children,
  onShowDetailsClick,
  status,
  statusDisplay,
}: Props): React.ReactNode => (
  <Row
    className={classNames(
      'align-items-center mb-1 border mr-0 position-relative',
      status === 'active' ? 'bg-white' : 'bg-light-grey',
    )}
    style={sectionStyles?.row}
  >
    {isSubsection && <div className={classNames('hr__annex-list-indent', { 'is-first': isFirstChild })} />}
    <div className="col-2" style={sectionStyles?.status}>
      <AgreementStatusBadge status={status} statusDisplay={statusDisplay} />
    </div>
    {children}
    <Col md={1} className="text-right" style={sectionStyles?.detailsIcon}>
      <i className="uil-eye font-16 cursor-pointer" onClick={onShowDetailsClick} />
    </Col>
  </Row>
)
