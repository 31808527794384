import * as React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { BaseModalProps } from '@components/modals/types'
import { useForm, useWatch } from 'react-hook-form'
import { SaveButton } from '@hyper/button'
import { CustomReactSelectOption, makeDefaultSelectOption } from '@components/custom-react-select'
import { Form } from '@hyper/forms/form'
import { FormSelect } from '@hyper/forms/form-select'
import { useNotificationHook } from '@hyper/use-notification-hook'
import { HRWorkerDocumentStatusOptions } from '@modules/hr/consts'
import { HrWorkerDetails, HrWorkerDocument } from '@modules/hr/workers/models'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { extractSelectOptionsValues } from '@helpers/utils'
import { useUpdateWorkerDocumentMutation } from '@api/agreements'

interface FormInputs {
  status: CustomReactSelectOption | null
}

interface Props extends BaseModalProps {
  workerDetails: HrWorkerDetails
  document: HrWorkerDocument
  onSubmit: () => void
}

export const HrWorkerDocumentStatusChangeModal: React.FC<Props> = ({
  document,
  workerDetails,
  onSubmit,
  toggleIsVisible,
}): React.ReactNode => {
  const { addSuccessMessage } = useNotificationHook()
  const user = useAuthenticatedUser()

  const [updateStatus, { isLoading }] = useUpdateWorkerDocumentMutation()

  const methods = useForm<FormInputs>({
    defaultValues: {
      status: makeDefaultSelectOption(HRWorkerDocumentStatusOptions, document.status),
    },
  })

  const selectedStatus = useWatch({ control: methods.control, name: 'status' })

  const handleSubmit = async (payload: FormInputs) => {
    await updateStatus({
      url: document.urls.details,
      payload: extractSelectOptionsValues(payload),
    }).unwrap()

    addSuccessMessage('Sukces', 'Status dokumentu został zmieniony.')
    onSubmit()
    toggleIsVisible()
  }

  const isOptionDisabled = (option: CustomReactSelectOption) => {
    const indexOfCurrentStatus = HRWorkerDocumentStatusOptions.findIndex(status => status.value === document.status)
    const indexOfOption = HRWorkerDocumentStatusOptions.findIndex(status => status.value === option.value)

    if (indexOfOption <= indexOfCurrentStatus) {
      return true
    }

    const isPolishCitizen = workerDetails.nationality === 'PL'

    const isToVerifyEnabled =
      !isPolishCitizen &&
      document.status === 'draft' &&
      user.hasPerm(UserPermission.HrWorkerProfileCanChangeDocumentStatus)

    const isVerifiedEnabled =
      !isPolishCitizen &&
      document.status === 'to_verify' &&
      user.hasPerm(UserPermission.HrWorkerProfileCanChangeDocumentStatus) &&
      user.hasPerm(UserPermission.HrWorkerProfileCanChangeDocumentStatusToVerified)

    const isActiveEnabled =
      (isPolishCitizen ? document.status === 'draft' : document.status === 'verified') &&
      user.hasPerm(UserPermission.HrWorkerProfileCanChangeDocumentStatus)

    return (
      indexOfOption > indexOfCurrentStatus &&
      ((option.value === 'to_verify' && !isToVerifyEnabled) ||
        (option.value === 'verified' && !isVerifiedEnabled) ||
        (option.value === 'active' && !isActiveEnabled))
    )
  }

  return (
    <Form methods={methods} onSubmit={handleSubmit}>
      <ModalHeader toggle={toggleIsVisible}>Zmień status dokumentu</ModalHeader>
      <ModalBody>
        <FormSelect
          name="status"
          options={HRWorkerDocumentStatusOptions}
          label="Status"
          selectProps={{ isOptionDisabled }}
        />
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-light" type="button" onClick={toggleIsVisible}>
          Anuluj
        </Button>
        <SaveButton label="Zastosuj" className="btn-green btn" isSaving={isLoading} disabled={!selectedStatus} />
      </ModalFooter>
    </Form>
  )
}
