import * as React from 'react'
import { CopyToClipboard } from '@components/copy-to-clipboard'
import { ReceptionBooking } from '@models/reception'
import { formatPhoneNumber } from '@helpers/utils'
import classNames from 'classnames'
import { useModal } from '@components/modals/use-modal'

interface Props {
  booking: ReceptionBooking
  emailClassName?: string
}

export const ReservationGuestContactData = ({ booking, emailClassName }: Props): React.ReactNode => {
  const [showSmsDialog] = useModal('ReceptionNotificationSmsDialog', { booking })
  const [showEmailDialog] = useModal('ReceptionNotificationEmailDialog', { booking })

  return (
    <>
      <ContactItem onIconClick={showEmailDialog} icon="uil-envelope-alt" className={emailClassName}>
        {booking.email}
        <CopyToClipboard className="ml-1" value={booking.email} />
      </ContactItem>

      <ContactItem onIconClick={showSmsDialog} icon="uil-comment-alt-message">
        {booking.phone ? formatPhoneNumber(booking.phone) : 'brak numeru telefonu'}
      </ContactItem>
    </>
  )
}

interface ContactItemProps {
  onIconClick: () => void
  icon: string
  className?: string
  children: React.ReactNode
}

const ContactItem = ({ onIconClick, icon, children, className }: ContactItemProps) => (
  <div className={classNames('d-flex mb-1', className)}>
    <i className={classNames('text-secondary font-16 lh-0 cursor-pointer mr-1', icon)} onClick={onIconClick} />
    <div className="font-weight-bold">{children}</div>
  </div>
)
