import * as React from 'react'
import { SubscriptionProduct } from '@modules/subscription/models'
import { EntranceTicketOption } from '@modules/subscription/create/options/entrance-ticket-option'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { createSelectOption, formatPrice, getById } from '@helpers/utils'
import {
  SubscriptionProductOptionRow,
  SubscriptionProductOptionsFormOption,
} from '@modules/subscription/create/options/product-option-row'

const TicketOptions = ['zoo_borysew', 'suntago']

const KINDS_TO_ADD = [
  { optionKind: 'client_data', formName: 'allow_change_client_data' }, // Zmiana danych (wielokrotna)
  { optionKind: 'localization_unlimited', formName: 'allow_subscription_code_localization_convert' }, // Zmiana lokalizacji (wielokrotna)
  { optionKind: 'zoo_borysew', formName: 'zoo_borysew' },
  { optionKind: 'suntago', formName: 'suntago' },
]

interface Props {
  product: SubscriptionProduct
}

export const SubscriptionProductOptionsForm = ({ product }: Props): React.ReactNode => {
  const { subscription_product_option_kinds } = useSubscriptionAppData()
  const options = product.options.reduce((prev: SubscriptionProductOptionsFormOption[], curr) => {
    const option = KINDS_TO_ADD.find(kind => kind.optionKind === curr.kind)
    if (!option) return prev

    const isAlreadyAdded = prev.find(el => el.name === option.formName)
    const createPrice = option => createSelectOption(formatPrice(option.price_brutto), option.price_brutto)

    if (isAlreadyAdded) {
      return prev.map(prevOption => {
        if (prevOption.name !== option.formName) return prevOption
        return { ...prevOption, prices: [...prevOption.prices, createPrice(curr)] }
      })
    }

    return [
      ...prev,
      {
        name: option.formName,
        prices: [createPrice(curr)],
        label: getById(subscription_product_option_kinds, curr.kind, 'key')?.value ?? '',
      },
    ]
  }, [])

  const { zoo_borysew, suntago, restOptions } = options.reduce(
    (prev, option) => {
      if (TicketOptions.includes(option.name)) return { ...prev, [option.name]: option }
      return { ...prev, restOptions: [...prev.restOptions, option] }
    },
    { zoo_borysew: null, suntago: null, restOptions: [] } as {
      zoo_borysew: SubscriptionProductOptionsFormOption | null
      suntago: SubscriptionProductOptionsFormOption | null
      restOptions: SubscriptionProductOptionsFormOption[]
    },
  )

  if (!options.length) return null

  return (
    <div className="w-100">
      <hr className="w-100" />
      <h5 className="ml-2 font-13 mb-2">Opcje dodatkowe</h5>

      {restOptions.map(option => (
        <SubscriptionProductOptionRow key={option.name} option={option} />
      ))}
      {zoo_borysew && (
        <EntranceTicketOption kind="zoo_borysew" priceOptions={zoo_borysew.prices} isEditDisabled={false} />
      )}
      {suntago && <EntranceTicketOption kind="suntago" priceOptions={suntago.prices} isEditDisabled={false} />}
    </div>
  )
}
